import i18n from "i18next";
import React from "react";
import { useTranslation, initReactI18next } from "react-i18next";
import en from "../src/Language/en.json";
import zh from "../src/Language/zh.json";

const languageResources = {
  en: { translation: en },
  zh: { translation: zh },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: languageResources,
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    returnObjects: true,
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
