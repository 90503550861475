import React from "react";
import styles from "../style.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  const title = t("title");
  const link = t("link");
  const aboutMe = t("aboutMe");
  return (
    <div className={styles.main}>
      <div className={styles.sectionTitleBox}>
        <div className={styles.titleBoxRow}>
          <h1 className={styles.title}>{title["2"]}</h1>
        </div>
      </div>
      <div className={styles.section}>
        <div>
          {/* <h3>
            We believe in better software for structural engineers. Software
            that constantly improves, focusing on efficiency and innovation.
          </h3> */}
          <p>{aboutMe["1"]}</p>
          <p>{aboutMe["2"]}</p>
          <p>{aboutMe["3"]}</p>
          {/* <p>by Star Catcher</p> */}
        </div>
      </div>
      <div className={styles.link}>
        <Link style={{ textDecoration: "none" }} to="../">
          {link["1"]}
        </Link>
      </div>
    </div>
  );
};

export default About;
