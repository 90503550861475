import { useRef, useEffect, useState } from "react";
import canvasStyles from "../CSS_Module/Beam_Calculator/canvas.module.css";
import {
  drawPointLoad,
  drawRollerSupport,
  drawPinedSupport,
  drawFixedSupport,
  drawUniformDistributedLoad,
  drawVariableDistributedLoad,
  drawMoment,
  drawDashedLine,
  drawSolidLine,
  drawRec,
  drawBeam,
  addText,
  drawGrid,
} from "./beam_diagram_element.js";
import { height } from "@fortawesome/free-brands-svg-icons/fa42Group";

function reOrder(elementList) {
  let newList = [];
  let supportList = [];
  let distributed_load = [];
  let concentrated_load = [];

  elementList.map((element) => {
    if (
      element.content == "fixed_support" ||
      element.content == "pined_support" ||
      element.content == "roller_support"
    ) {
      supportList.push(element);
    } else if (element.content == "point_load" || element.content == "moment") {
      concentrated_load.push(element);
    } else if (
      element.content == "uniform_distributed_load" ||
      element.content == "variable_distributed_load"
    ) {
      distributed_load.push(element);
    } else {
      newList.push(element);
    }
  });

  newList = newList.concat(supportList, distributed_load, concentrated_load);
  return newList;
}

function DrawMainBeamDiagram(
  context,
  beamLengthValue,
  elementList,
  canvasWidth,
  canvasHeight,
  unitSystem
) {
  let beamAxis = canvasHeight / 2;
  let beamPixelLength = canvasWidth * 0.7;

  let beamWidth = 0;

  if (canvasWidth <= 500) {
    beamWidth = 20;
  } else {
    beamWidth = 40;
  }

  let all_circles = [];
  let beamLeftEnd = canvasWidth * 0.15;
  let beamRightEnd = beamLeftEnd + beamPixelLength;
  let beamLength = beamLengthValue;
  var location1 = 0;
  var location2 = 0;
  var value = 0;
  var value2 = 0;
  drawGrid(context, 0, 0, canvasWidth, canvasHeight, beamAxis);
  drawBeam(context, beamLeftEnd, beamPixelLength, beamAxis, canvasWidth);

  let newList = reOrder(elementList);

  newList.map((element) => {
    if (
      !isNaN(element.location) &&
      element.location != "" &&
      !isNaN(element.location2) &&
      !isNaN(element.value)
    ) {
      location1 =
        beamLeftEnd + beamPixelLength * (element.location / beamLength);
      location2 =
        beamLeftEnd + beamPixelLength * (element.location2 / beamLength);
      value = element.value;
      value2 = element.value2;
      if (
        location1 < beamLeftEnd ||
        location1 > beamRightEnd ||
        location2 < beamLeftEnd ||
        location2 > beamRightEnd
      ) {
      } else {
        switch (element.content) {
          case "roller_support":
            drawRollerSupport(
              context,
              location1,
              beamAxis + beamWidth / 2,
              canvasWidth
            );
            break;
          case "pined_support":
            drawPinedSupport(
              context,
              location1,
              beamAxis + beamWidth / 2,
              canvasWidth
            );
            break;
          case "fixed_support":
            drawFixedSupport(
              context,
              location1,
              beamAxis,
              beamLeftEnd,
              beamRightEnd,
              canvasWidth
            );
            break;
          case "point_load":
            drawPointLoad(
              context,
              location1,
              beamAxis - beamWidth / 2,
              value,
              unitSystem,
              canvasWidth,
              beamWidth
            );
            break;
          case "uniform_distributed_load":
            drawUniformDistributedLoad(
              context,
              location1,
              location2,
              beamAxis - beamWidth / 2,
              value,
              unitSystem,
              canvasWidth,
              beamWidth
            );
            break;
          case "variable_distributed_load":
            drawVariableDistributedLoad(
              context,
              location1,
              location2,
              beamAxis - beamWidth / 2,
              value,
              value2,
              unitSystem,
              canvasWidth,
              beamWidth
            );
            break;
          case "moment":
            drawMoment(
              context,
              location1,
              beamAxis,
              value,
              unitSystem,
              canvasWidth
            );
            break;
          // case "beam":
          //   return <Beam length={selectedElement.length} setBeamLengthValue={setBeamLengthValue}/>;
          default:
            return "No element";
        }
      }
    }
  });
}

const Diagram = ({
  diagramElementList,
  setDiagramElementList,
  elementList,
  beamLengthValue,
  canvasSize,
  setCanvasSize,
  unitSystem,
}) => {
  const canvasRef2 = useRef(null);

  useEffect(() => {
    const canvas = canvasRef2.current;
    const diagramAreaObject = document.getElementById("diagramArea");
    let rect = diagramAreaObject.getBoundingClientRect();

    const handleResize = () => {
      if (window.innerWidth * 0.8 - 320 <= 600 && window.innerWidth > 500) {
        if (window.innerHeight < 500) {
          setCanvasSize({ width: canvasSize.width, height: canvasSize.height });
        } else {
          setCanvasSize({ width: 600, height: rect.height });
        }
      } else if (window.innerWidth <= 500) {
        if (canvasSize.width>500){
          setCanvasSize({ width: window.innerWidth, height: 304 }); //for shrinking screen on desktop app
        } else {
          setCanvasSize({ width: canvasSize.width, height: 304 }); 
        }
      } else {
        setCanvasSize({
          width: window.innerWidth * 0.8 - 320,
          // height: rect.height,
          height: 704,
        });
      }
    };

    canvas.width = canvasSize.width;
    canvas.height = canvasSize.height;
    const context = canvas.getContext("2d");
    DrawMainBeamDiagram(
      context,
      beamLengthValue,
      elementList,
      canvasSize.width,
      canvasSize.height,
      unitSystem
    );

    window.addEventListener("resize", handleResize);
    // handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [elementList, canvasSize, unitSystem]);

  return (
    <div id="diagramArea" className={canvasStyles.diagramArea}>
      <canvas
        id="mainCanvas"
        // className={styles.beamDiagramCanvas}
        ref={canvasRef2}
      />
    </div>
  );
};

export default Diagram;
