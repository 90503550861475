
export default class Support{
    constructor(type, location) {
        this.type = type;
        this.location = location;
    }

    getType() {
        return this.type;
    }

    getLocation() {
        return this.location;
    }

} 