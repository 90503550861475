import DataPoint from "./datapoint";

const DataPointList = ({ dataPointList }) => {
  return (
    <div>
      {dataPointList.map((newPoint) => (
        <DataPoint
          key={newPoint.id}
          cx={newPoint.cx}
          cy={newPoint.cy}
          location={newPoint.location}
          value={newPoint.value}
        />
      ))}
    </div>
  );
};

export default DataPointList;
