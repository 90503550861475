import { useState, useEffect } from "react";
import modalStyles from "../CSS_Module/modal.module.css";

import i18n from "../i18nextService";
import { useTransition, useTranslation } from "react-i18next";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faTimes);

const Settings = ({
  isSettingActive,
  setIsSettingActive,
  setUnitSystem,
  currentUnit,
  setCurrentUnit,
}) => {
  const { t } = useTranslation();

  if (!isSettingActive) return null;

  const handleUnitChange = (event) => {
    let unit = event.target.value;
    if (unit == "metric") {
      setCurrentUnit("metric");
    } else {
      setCurrentUnit("imperial");
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (currentUnit == "metric") {
      setUnitSystem(["N", "kg", "m"]);
    } else {
      setUnitSystem(["lbf", "lbm", "ft"]);
    }
    setIsSettingActive(false);
  };

  return (
    <div className={modalStyles.blurBackground}>
      <div className={modalStyles.modalBox}>
        <div className={modalStyles.modalBanner}>
          <FontAwesomeIcon
            onClick={() => {
              setIsSettingActive(false);
            }}
            className={modalStyles.icon}
            icon="times"
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className={modalStyles.modalBody}>
            <div className={modalStyles.formTitle}>{t("modalHeader")["3"]}</div>
            <div className={modalStyles.inputFormDropdownGrid}>
              <div className={modalStyles.errorSpacer}></div>
              <div className={modalStyles.errorSpacer}></div>
              <label className={modalStyles.formInputLabel} htmlFor="unit">
                {t("inputType")["7"]}:
              </label>
              <select
                className={modalStyles.dropdown}
                name="unit"
                id="unit"
                value={currentUnit}
                onChange={handleUnitChange}
              >
                <option value="metric">{t("unitSystem")["1"]}</option>
                <option value="imperial">{t("unitSystem")["2"]}</option>
              </select>
              <div className={modalStyles.errorSpacer}></div>
              <div className={modalStyles.errorSpacer}></div>
            </div>
            <button className={modalStyles.formButton}>
              {t("buttonName")["2"]}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Settings;
