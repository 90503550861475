const handleExampleClick = (
  event,
  setElementList,
  elementList,
  beamLengthValue,
  setBeamLengthValue
) => {
  event.preventDefault();
  //   const ELArray = ["roller_support", "pined_support", "fixed_support"];
  // const newList = elementList.map((item) =>{
  //     if (!supportArray.includes(item.content)){
  //         return item;
  //     }
  // })

  //   const newList = elementList.filter((element) => element.content == "beam");
  setBeamLengthValue("100");
  const newList = [
    {
      content: "beam",
      location: "",
      location2: "",
      value: "",
      value2: "",
      error: "",
      id: "beamBody",
      length: beamLengthValue,
    },
  ];

  let beamLength = 100;

  const d = new Date();
  let timeKey = d.getTime();
  let randomKey = Math.floor(Math.random() * 6);
  let randomKey1 = Math.floor(Math.random() * 10000);
  let uniqueKey =
    "predefined" + timeKey.toString() + "w" + randomKey1.toString() + "X001";
  let uniqueKey2 =
    "predefined" + timeKey.toString() + "w" + randomKey1.toString() + "Y002";
  let uniqueKey3 =
    "predefined" + timeKey.toString() + "w" + randomKey1.toString() + "Z003";
  let selectedBeam = randomKey;

  if (selectedBeam == 1) {
    setElementList([
      ...newList,
      {
        content: "pined_support",
        location: "0",
        location2: "",
        value: "",
        value2: "",
        error: "",
        id: uniqueKey,
        varName: "S1",
      },
      {
        content: "pined_support",
        location: String(beamLength),
        location2: "",
        value: "",
        value2: "",
        error: "",
        id: uniqueKey2,
        varName: "S2",
      },
      {
        content: "point_load",
        location: String(Math.round(0.5 * Number(beamLength) * 100) / 100),
        location2: "",
        value: "-50",
        value2: "",
        error: "",
        id: uniqueKey3,
        varName: "F1",
      },
    ]);
  } else if (selectedBeam == 2) {
    setElementList([
      ...newList,
      {
        content: "fixed_support",
        location: "0",
        location2: "",
        value: "",
        value2: "",
        error: "",
        id: uniqueKey,
        varName: "S1",
      },
      {
        content: "uniform_distributed_load",
        location: String(Math.round(0.5 * Number(beamLength) * 100) / 100),
        location2: String(beamLength),
        value: "-5",
        value2: "",
        error: "",
        id: uniqueKey2,
        varName: "L1",
      },
    ]);
  } else if (selectedBeam == 3) {
    setElementList([
      ...newList,
      {
        content: "pined_support",
        location: String(Math.round(0.3 * Number(beamLength) * 100) / 100),
        location2: "",
        value: "",
        value2: "",
        error: "",
        id: uniqueKey,
        varName: "S1",
      },
      {
        content: "pined_support",
        location: String(Math.round(0.7 * Number(beamLength) * 100) / 100),
        location2: "",
        value: "",
        value2: "",
        error: "",
        id: uniqueKey2,
        varName: "S2",
      },
      {
        content: "point_load",
        location: String(Math.round(0.5 * Number(beamLength) * 100) / 100),
        location2: "",
        value: "-50",
        value2: "",
        error: "",
        id: uniqueKey3,
        varName: "F1",
      },
    ]);
  } else if (selectedBeam == 4) {
    setElementList([
      ...newList,
      {
        content: "pined_support",
        location: String(Math.round(0.3 * Number(beamLength) * 100) / 100),
        location2: "",
        value: "",
        value2: "",
        error: "",
        id: uniqueKey,
        varName: "S1",
      },
      {
        content: "pined_support",
        location: String(Math.round(0.7 * Number(beamLength) * 100) / 100),
        location2: "",
        value: "",
        value2: "",
        error: "",
        id: uniqueKey2,
        varName: "S2",
      },
      {
        content: "uniform_distributed_load",
        location: String(Math.round(0.3 * Number(beamLength) * 100) / 100),
        location2: String(Math.round(0.7 * Number(beamLength) * 100) / 100),
        value: "-5",
        value2: "",
        error: "",
        id: uniqueKey3,
        varName: "L1",
      },
    ]);
  } else if (selectedBeam == 5) {
    setElementList([
      ...newList,
      {
        content: "fixed_support",
        location: "0",
        location2: "",
        value: "",
        value2: "",
        error: "",
        id: uniqueKey,
        varName: "S1",
      },
      {
        content: "variable_distributed_load",
        location: "0",
        location2: String(beamLength),
        value: "-6",
        value2: "-12",
        error: "",
        id: uniqueKey2,
        varName: "L1",
      },
    ]);
  } else {
    setElementList([
      ...newList,
      {
        content: "pined_support",
        location: "0",
        location2: "",
        value: "",
        value2: "",
        error: "",
        id: uniqueKey,
        varName: "S1",
      },
      {
        content: "pined_support",
        location: String(beamLength),
        location2: "",
        value: "",
        value2: "",
        error: "",
        id: uniqueKey2,
        varName: "S2",
      },
      {
        content: "variable_distributed_load",
        location: "0",
        location2: String(beamLength),
        value: "-12",
        value2: "-6",
        error: "",
        id: uniqueKey3,
        varName: "L1",
      },
    ]);
  }
};

export default handleExampleClick;
