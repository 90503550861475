import modalStyles from "../CSS_Module/modal.module.css";
import reportStyles from "../CSS_Module/Beam_Calculator/report.module.css";
import { Printer } from "../Calculator_Logic/test_print";

import {
  EquationSolver,
  printMatrix,
} from "../Calculator_Logic/equation_solver.js";
import { Matrixify } from "../Calculator_Logic/matrixify.js";
import { solveShearAndMoment } from "../Calculator_Logic/shear_and_moment_solver.js";
import { criticalPointSolver } from "../Calculator_Logic/critical_point_solver.js";
import BeamElement from "../Calculator_Logic/element.js";
import Support from "../Calculator_Logic/support.js";
import DistributedLoad from "../Calculator_Logic/distributed_load.js";
import AppliedMoment from "../Calculator_Logic/applied_moment.js";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes, faFileExport } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShearAndMomentDiagram from "../Diagram/shear_and_moment_diagram.js";
import Beam from "../Calculator_Logic/beam.js";

library.add(faTimes, faFileExport);

function toPDF() {
  window.print();
}

function assignVariableName(input) {
  const aphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"];
  const nameArray = [];
  let counter = 0;

  for (let i = 0; i < input.length; i++) {
    if (
      input[i].getType() == "support" ||
      input[i].getType() == "fixed_support"
    ) {
      let name = "F_" + aphabet[counter];
      nameArray.push(name);
      if (input[i].getType() == "fixed_support") {
        name = "M_" + aphabet[counter];
        nameArray.push(name);
      }
      counter += 1;
    }
  }

  return nameArray;
}

const Result = ({
  isSubmitted,
  setIsSubmitted,
  elementList,
  beamLengthValue,
  diagramType,
  unitSystem,
}) => {
  if (!isSubmitted) return null;

  const input = [];

  let reactionLocationList = [];

  let thisElement = null;
  elementList.map((newElement) => {
    //Must add break, otherwise, fall through will happen
    switch (newElement.content) {
      case "roller_support":
        thisElement = new Support("support", parseFloat(newElement.location));
        reactionLocationList.push({
          type: "point-load",
          location: newElement.location,
        });
        break;
      case "pined_support":
        thisElement = new Support("support", parseFloat(newElement.location));
        reactionLocationList.push({
          type: "point-load",
          location: newElement.location,
        });
        break;
      case "fixed_support":
        thisElement = new Support(
          "fixed_support",
          parseFloat(newElement.location)
        );
        reactionLocationList.push({
          type: "point-load",
          location: newElement.location,
        });
        reactionLocationList.push({
          type: "moment",
          location: newElement.location,
        });
        break;
      case "point_load":
        thisElement = new BeamElement(
          "force",
          parseFloat(newElement.location),
          parseFloat(newElement.value)
        );
        break;
      case "uniform_distributed_load":
        thisElement = new DistributedLoad(
          "distributed_load",
          parseFloat(newElement.location),
          parseFloat(newElement.location2),
          parseFloat(newElement.value)
        );
        break;
      case "variable_distributed_load":
        thisElement = new DistributedLoad(
          "variable_distributed_load",
          parseFloat(newElement.location),
          parseFloat(newElement.location2),
          parseFloat(newElement.value),
          parseFloat(newElement.value2)
        );
        break;
      case "moment":
        thisElement = new AppliedMoment(
          "moment",
          parseFloat(newElement.location),
          parseFloat(newElement.value)
        );
        break;
      case "beam":
        thisElement = new Beam("beam", parseFloat(newElement.length));
        break;
      default:
        thisElement = "No Match";
    }
    input.push(thisElement);
  });

  const output = Matrixify(input);

  const solution = EquationSolver(output[0], output[1]);

  const roundedSolution = [];

  let reactionList = [];

  for (let i = 0; i < solution.length; i++) {
    roundedSolution.push(Math.round(100 * solution[i]) / 100);
  }

  for (let j = 0; j < reactionLocationList.length; j++) {
    if (reactionLocationList[j]["type"] == "moment") {
      reactionList.push({
        content: "moment",
        location: reactionLocationList[j]["location"],
        value: roundedSolution[j],
      });
    } else {
      reactionList.push({
        content: "point_load",
        location: reactionLocationList[j]["location"],
        value: roundedSolution[j],
      });
    }
  }

  const ShearMomentResult = solveShearAndMoment(
    roundedSolution,
    input,
    beamLengthValue
  );
  const variableName = assignVariableName(input);
  const criticalPoint = criticalPointSolver(ShearMomentResult);

  return (
    <div className={modalStyles.blurBackground}>
      <div className={reportStyles.shearAndMomentDiagramBox}>
        <div className={modalStyles.modalBanner}>
          <FontAwesomeIcon
            onClick={() => {
              setIsSubmitted(false);
            }}
            className={modalStyles.icon}
            icon="times"
          />
        </div>
        <div className={reportStyles.shearAndMomentCanvasContainer}>
          <div className={reportStyles.shearAndMomentDiagramBoxTitle}>
            {diagramType["4"]}
          </div>
          <ShearAndMomentDiagram
            beamLengthValue={beamLengthValue}
            elementList={elementList}
            solution={roundedSolution}
            ShearMomentResult={ShearMomentResult}
            criticalPoint = {criticalPoint}
            variableName={variableName}
            reactionList={reactionList}
            diagramType={diagramType}
            unitSystem={unitSystem}
          ></ShearAndMomentDiagram>
          <div className={reportStyles.shearAndMomentDiagramFooter}>
            Generated by MESOLVER
          </div>
        </div>
      </div>
    </div>
  );
};

export default Result;
