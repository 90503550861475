import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import elementListPanelStyles from "../CSS_Module/Beam_Calculator/elementListPanel.module.css";
import Element from "./element";

const ElementListPanel = ({
  elementList,
  setElementList,
  beamLengthValue,
  setBeamLengthValue,
  elementTypeTranslation,
  unitSystem,
}) => {
  const { t } = useTranslation();
  const elementListPanelErrorMsg = t("elementListPanelErrorMsg");

  useEffect(() => {
    updateElementList(beamLengthValue);
  }, [beamLengthValue]);

  function changeVarName(type, id, newList) {
    let variableName = "";
    let counter = [1, 1, 1, 1]; //[support, force, moment, distributed_load]

    const supportArray = ["roller_support", "pined_support", "fixed_support"];
    // const concentratedLoadArray = ["point_load", "moment"];
    const distributedLoadArray = [
      "uniform_distributed_load",
      "variable_distributed_load",
    ];
    let stop = false;

    newList.map((item) => {
      if (item.id != id && !stop) {
        if (supportArray.includes(item.content)) {
          counter[0] += 1;
        } else if (distributedLoadArray.includes(item.content)) {
          counter[3] += 1;
        } else if (item.content == "point_load") {
          counter[1] += 1;
        } else if (item.content == "moment") {
          counter[2] += 1;
        }
      } else {
        stop = true;
      }
    });

    if (supportArray.includes(type)) {
      variableName = "S" + counter[0];
    } else if (distributedLoadArray.includes(type)) {
      variableName = "L" + counter[3];
    } else if (type == "point_load") {
      variableName = "F" + counter[1];
    } else if (type == "moment") {
      variableName = "M" + counter[2];
    } else {
      variableName = "Undefined";
    }

    return variableName;
  }

  function handleRemove(id) {
    let newList = elementList.filter((element) => element.id !== id);

    newList = newList.map((item) => {
      const updatedElement = {
        ...item,
        varName: changeVarName(item.content, item.id, newList),
      };
      return updatedElement;
    });

    setElementList(newList);
  }

  const handleLengthInput = (event) => {
    setBeamLengthValue(event.target.value);
  };

  function updateElementList(newBeamValue) {
    let updatedElement = {};
    let newList = elementList.map((item) => {
      if (item.id == "beamBody") {
        updatedElement = {
          ...item,
          length: newBeamValue,
          error: validate(
            item.location,
            item.location2,
            newBeamValue,
            item.value2,
            item.content
          ),
        };
      } else {
        updatedElement = {
          ...item,
          error: validate(
            item.location,
            item.location2,
            item.value,
            item.value2,
            item.content
          ),
        };
      }
      return updatedElement;
    });
    setElementList(newList);
  }

  function distance1Check(distance1, preError) {
    let error = preError;
    if (
      !isNaN(distance1) &&
      !distance1.includes(" ") &&
      !distance1.length == 0
    ) {
      if (Number(distance1) < 0) {
        error = elementListPanelErrorMsg["1"]; //'Location cannot be negative'
      } else if (
        Number(distance1) > Number(beamLengthValue) &&
        Number(beamLengthValue) > 0
      ) {
        error = elementListPanelErrorMsg["2"]; //'Location is not on the beam'
      }
    } else if (distance1.includes(" ")) {
      error = elementListPanelErrorMsg["3"]; //'No space is allowed'
    } else if (distance1.includes(",")) {
      error = elementListPanelErrorMsg["4"]; //'Comma is not allowed'
    } else if (distance1.length == 0) {
      error = elementListPanelErrorMsg["6"]; //'No empty field is allowed'
    } else {
      error = elementListPanelErrorMsg["5"]; //'Input is not a number'
    }
    return error;
  }

  function distance2Check(distance1, distance2, preError) {
    let error = preError;
    if (
      !isNaN(distance2) &&
      !distance2.includes(" ") &&
      !distance2.length == 0
    ) {
      if (Number(distance2) < 0) {
        error = elementListPanelErrorMsg["1"]; //'Location cannot be negative'
      } else if (
        Number(distance2) > Number(beamLengthValue) &&
        Number(beamLengthValue) > 0
      ) {
        error = elementListPanelErrorMsg["2"]; //'Location is not on the beam'
      } else if (
        !distance1.length == 0 &&
        !isNaN(distance1) &&
        !distance2.length == 0 &&
        Number(distance2) < Number(distance1)
      ) {
        error = elementListPanelErrorMsg["7"]; //'Rule: To > From'
      }
    } else if (distance2.includes(" ")) {
      error = elementListPanelErrorMsg["3"]; //'No space is allowed'
    } else if (distance2.includes(",")) {
      error = elementListPanelErrorMsg["4"]; //'Comma is not allowed'
    } else if (distance2.length == 0) {
      error = elementListPanelErrorMsg["6"]; //'No empty field is allowed'
    } else {
      error = elementListPanelErrorMsg["5"]; //'Input is not a number'
    }
    return error;
  }

  function magnitudeCheck(magnitude, preError, type) {
    let error = preError;
    if (
      !isNaN(magnitude) &&
      !String(magnitude).includes(" ") &&
      !String(magnitude).length == 0
    ) {
      if (Number(magnitude) == 0 && type != "variable_distributed_load") {
        error = elementListPanelErrorMsg["12"]; //'Magnitude must not be 0'
      }
    } else if (String(magnitude).includes(" ")) {
      error = elementListPanelErrorMsg["3"]; //'No space is allowed'
    } else if (String(magnitude).includes(",")) {
      error = elementListPanelErrorMsg["4"]; //'Comma is not allowed'
    } else if (String(magnitude).length == 0) {
      error = elementListPanelErrorMsg["6"]; //'No empty field is allowed'
    } else {
      error = elementListPanelErrorMsg["5"]; //'Input is not a number'
    }
    return error;
  }

  function magnitude2Check(magnitude, magnitude2, preError) {
    let error = preError;
    if (
      !isNaN(magnitude2) &&
      !String(magnitude2).includes(" ") &&
      !String(magnitude2).length == 0 &&
      Number(magnitude * magnitude2) >= 0
    ) {
      if (Number(magnitude) == 0 && Number(magnitude2) == 0) {
        error = elementListPanelErrorMsg["11"]; // 'Intensities must not both be 0'
      }
    } else if (String(magnitude2).includes(" ")) {
      error = elementListPanelErrorMsg["3"]; //'No space is allowed'
    } else if (String(magnitude2).includes(",")) {
      error = elementListPanelErrorMsg["4"]; //'Comma is not allowed'
    } else if (String(magnitude2).length == 0) {
      error = elementListPanelErrorMsg["6"]; //'No empty field is allowed'
    } else if (Number(magnitude * magnitude2) < 0) {
      error = elementListPanelErrorMsg["10"]; //'Intensity should both be - or +'
    } else {
      error = elementListPanelErrorMsg["5"]; //'Input is not a number'
    }
    return error;
  }

  function beamLengthCheck(lengthValue) {
    let error = "";
    if (
      !isNaN(lengthValue) &&
      !lengthValue.includes(" ") &&
      !lengthValue.length == 0
    ) {
      if (Number(lengthValue) < 0) {
        error = elementListPanelErrorMsg["8"]; //Beam length cannot be negative
      } else if (Number(lengthValue) == 0) {
        error = elementListPanelErrorMsg["9"]; //Beam length cannot be zero
      }
    } else if (lengthValue.includes(" ")) {
      error = elementListPanelErrorMsg["3"]; //No space is allowed
    } else if (lengthValue.includes(",")) {
      error = elementListPanelErrorMsg["4"]; //Comma is not allowed
    } else if (lengthValue.length == 0) {
      error = elementListPanelErrorMsg["6"]; //This field cannot be empty
    } else {
      error = elementListPanelErrorMsg["5"]; //Input is not a number
    }
    return error;
  }

  function validate(
    newDistance1,
    newDistance2,
    newMagnitude,
    newMagnitude2,
    type
  ) {
    let error = "";
    let distance1 = newDistance1;
    let distance2 = newDistance2;
    let magnitude = newMagnitude;
    let magnitude2 = newMagnitude2;
    let typeIndex = 0;

    const supportArray = ["roller_support", "pined_support", "fixed_support"];
    const concentratedLoadArray = ["point_load", "moment"];
    const distributedLoadArray = [
      "uniform_distributed_load",
      "variable_distributed_load",
    ];

    if (supportArray.includes(type)) {
      typeIndex = 1;
    } else if (concentratedLoadArray.includes(type)) {
      typeIndex = 2;
    } else if (distributedLoadArray.includes(type)) {
      if (type == "uniform_distributed_load") {
        typeIndex = 3;
      } else {
        typeIndex = 5;
      }
    } else if (type == "beam") {
      typeIndex = 4;
    } else {
      typeIndex = 0;
    }

    if (typeIndex == 1 || typeIndex == 2 || typeIndex == 3 || typeIndex == 5) {
      error = distance1Check(distance1, error);
      if (typeIndex == 2 || typeIndex == 3 || typeIndex == 5) {
        error = magnitudeCheck(magnitude, error, type);
        if (typeIndex == 3 || typeIndex == 5) {
          error = distance2Check(distance1, distance2, error);
          if (typeIndex == 5) {
            error = magnitude2Check(magnitude, magnitude2, error);
          }
        }
      }
    } else if (typeIndex == 4) {
      error = beamLengthCheck(newMagnitude);
    } else {
      error = "Type undefined"; //'Type undefined'
    }
    return error;
  }

  function handleInputChange(
    id,
    newLocation1,
    newLocation2,
    newValue,
    newValue2,
    type
  ) {
    // const validate = (newDistance1, newDistance2, newMagnitude, type) => {
    //   let error = '';
    //   let distance1 = newDistance1;
    //   let distance2 = newDistance2;
    //   let magnitude = newMagnitude;
    //   let currentType = type;
    //   // let intensity = Number(input.intensity);

    //   if (!isNaN(distance1) && !distance1.includes(" ") && !distance1.length==0){
    //     if(Number(distance1) < 0){
    //       error = 'Location cannot be negative';
    //     } else if (Number(distance1) > Number(beamLengthValue)){
    //       error = 'Location is not on the beam';
    //     }
    //   } else if (distance1.includes(" ")) {
    //     error = 'No space is allowed';
    //   } else if (distance1.length==0){
    //     error = 'No empty field is allowed';
    //   } else {
    //     error = 'Input is not a number';
    //   }

    //   if (!isNaN(distance2) && !distance2.includes(" ") && !distance2.length==0){
    //     if(Number(distance2) < 0){
    //       error = 'Location cannot be negative';
    //     } else if (Number(distance2) > Number(beamLengthValue)){
    //       error = 'Location is not on the beam';
    //     } else if (!distance1.length==0 && !isNaN(distance1) && !distance2.length==0 && Number(distance2)<Number(distance1)) {
    //       error = 'Rule: To > From';
    //     }
    //   } else if (distance2.includes(" ")) {
    //     error = 'No space is allowed';
    //   } else if (distance2.length==0){
    //     error = 'No empty field is allowed';
    //   } else {
    //     error = 'Input is not a number';
    //   }

    //   if (!isNaN(magnitude) && !magnitude.includes(" ") && !magnitude.length==0){
    //   } else if (magnitude.includes(" ")) {
    //     error = 'No space is allowed';
    //   } else if (magnitude.length==0){
    //     error = 'No empty field is allowed';
    //   } else {
    //     error = 'Input is not a number';
    //   }

    //   // if (!isNaN(distance1)){
    //   //   if(distance1 < 0){
    //   //     error = 'Location cannot be negative';
    //   //   } else if (Number(distance1) > Number(beamLengthValue)){
    //   //     error = 'Location is not on the beam';

    //   //   }
    //   // } else {
    //   //   error = 'Input is not a number';
    //   // }

    //   return error;
    // }

    const newList = elementList.map((item) => {
      if (item.id === id) {
        const updatedElement = {
          ...item,
          location: newLocation1,
          location2: newLocation2,
          value: newValue,
          value2: newValue2,
          error: validate(
            newLocation1,
            newLocation2,
            newValue,
            newValue2,
            type
          ),
        };
        return updatedElement;
      }
      return item;
    });

    setElementList(newList);
  }

  return (
    <div className={elementListPanelStyles.elementListPanel}>
      {/* <Element key="beam" selectedElement={{content:'beam', location:'', location2:'', value:'', error:'', length:beamLengthValue}} handleLengthInput={handleLengthInput}/>  */}
      {elementList.map((newElement) => (
        <Element
          key={newElement.id}
          selectedElement={newElement}
          id={newElement.id}
          handleRemove={handleRemove}
          handleInputChange={handleInputChange}
          beamLengthValue={beamLengthValue}
          handleLengthInput={handleLengthInput}
          elementTypeTranslation={elementTypeTranslation}
          unitSystem={unitSystem}
        />
      ))}
    </div>
  );
};

export default ElementListPanel;
