import { useState } from "react";
import elementListPanelStyles from "../CSS_Module/Beam_Calculator/elementListPanel.module.css";
import roller_support_icon from "../Element_Image/Element_Icons/roller_support-w.svg";
import pined_support_icon from "../Element_Image/Element_Icons/pined_support-w.svg";
import fixed_support_icon from "../Element_Image/Element_Icons/fixed_support-w.svg";
import point_load_icon from "../Element_Image/Element_Icons/point_load-w.svg";
import uniform_distributed_load_icon from "../Element_Image/Element_Icons/uniformly_distributed_load-w.svg";
import variable_distributed_load_icon from "../Element_Image/Element_Icons/variable_distributed_load-w.svg";
import moment_icon from "../Element_Image/Element_Icons/moment-w.svg";
import beam_icon from "../Element_Image/Element_Icons/beam-w.svg";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faCheckSquare,
  faCoffee,
  faPlus,
  faSliders,
  faTimes,
  faUndo,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faTimes);

const PointLoad = ({
  inputLocation,
  handleDistanceInput,
  inputMagnitude,
  handleMagnitudeInput,
  handleRemove,
  id,
  error,
  type,
  varName,
  unitSystem,
}) => {
  return (
    <div className={elementListPanelStyles.elementBox}>
      <div className={elementListPanelStyles.element}>
        <FontAwesomeIcon
          className={elementListPanelStyles.deleteElementIcon}
          icon="times"
          onClick={() => handleRemove(id)}
        />
        <div className={elementListPanelStyles.elementIconContainer}>
          <img
            className={elementListPanelStyles.elementIcon}
            src={point_load_icon}
            alt="Load"
          />
          <div>{varName}</div>
        </div>
        <div className={elementListPanelStyles.elementInput}>
          <div className={elementListPanelStyles.elementInputGrid}>
            <label
              htmlFor="distance1"
              className={elementListPanelStyles.elementInputLabel}
            >
              d
            </label>
            <div className={elementListPanelStyles.equal}>=</div>
            <input
              className={elementListPanelStyles.elementInputBox}
              required
              name="distance1"
              id="distance1"
              value={inputLocation}
              onChange={(event) => handleDistanceInput(event, id, type)}
            ></input>
            <div className={elementListPanelStyles.unit}>{unitSystem[2]}</div>
            <label
              htmlFor="magnitude"
              className={elementListPanelStyles.elementInputLabel}
            >
              F
            </label>
            <div className={elementListPanelStyles.equal}>=</div>
            <input
              className={elementListPanelStyles.elementInputBox}
              required
              name="magnitude"
              id="magnitude"
              value={inputMagnitude}
              onChange={(event) => handleMagnitudeInput(event, id, type)}
            ></input>
            <div className={elementListPanelStyles.unit}>{unitSystem[0]}</div>
          </div>
        </div>
      </div>
      <div className={elementListPanelStyles.elementListInputError}>
        {error}
      </div>
    </div>
  );
};

const UniformDistributedLoad = ({
  inputLocation,
  handleDistanceInput,
  inputLocation2,
  handleDistanceInput2,
  inputMagnitude,
  handleMagnitudeInput,
  handleRemove,
  id,
  error,
  type,
  varName,
  unitSystem,
}) => {
  return (
    <div className={elementListPanelStyles.elementBox}>
      <div className={elementListPanelStyles.element}>
        <FontAwesomeIcon
          className={elementListPanelStyles.deleteElementIcon}
          icon="times"
          onClick={() => handleRemove(id)}
        />
        <div className={elementListPanelStyles.elementIconContainer}>
          <img
            className={elementListPanelStyles.elementIcon}
            src={uniform_distributed_load_icon}
            alt="Load"
          />
          <div>{varName}</div>
        </div>
        <div className={elementListPanelStyles.elementInput}>
          <div className={elementListPanelStyles.elementInputGrid}>
            <label
              htmlFor="distance1"
              className={elementListPanelStyles.elementInputLabel}
            >
              d1
            </label>
            <div className={elementListPanelStyles.equal}>=</div>
            <input
              className={elementListPanelStyles.elementInputBox}
              required
              name="distance1"
              id="distance1"
              value={inputLocation}
              onChange={(event) => handleDistanceInput(event, id, type)}
            ></input>
            <div className={elementListPanelStyles.unit}>{unitSystem[2]}</div>
            <label
              htmlFor="distance2"
              className={elementListPanelStyles.elementInputLabel}
            >
              d2
            </label>
            <div className={elementListPanelStyles.equal}>=</div>
            <input
              className={elementListPanelStyles.elementInputBox}
              required
              name="distance2"
              id="distance2"
              value={inputLocation2}
              onChange={(event) => handleDistanceInput2(event, id, type)}
            ></input>
            <div className={elementListPanelStyles.unit}>{unitSystem[2]}</div>
            <label
              htmlFor="magnitude"
              className={elementListPanelStyles.elementInputLabel}
            >
              I
            </label>
            <div className={elementListPanelStyles.equal}>=</div>
            <input
              className={elementListPanelStyles.elementInputBox}
              required
              name="magnitude"
              id="magnitude"
              value={inputMagnitude}
              onChange={(event) => handleMagnitudeInput(event, id, type)}
            ></input>
            <div className={elementListPanelStyles.unit}>
              {unitSystem[0]}/{unitSystem[2]}
            </div>
          </div>
        </div>
      </div>
      <div className={elementListPanelStyles.elementListInputError}>
        {error}
      </div>
    </div>
  );
};

const VariableDistributedLoad = ({
  inputLocation,
  handleDistanceInput,
  inputLocation2,
  handleDistanceInput2,
  inputMagnitude,
  handleMagnitudeInput,
  inputMagnitude2,
  handleMagnitudeInput2,
  handleRemove,
  id,
  error,
  type,
  varName,
  unitSystem,
}) => {
  // let intensity = "f(x) = " + inputMagnitude + "x";
  return (
    <div className={elementListPanelStyles.elementBox}>
      <div className={elementListPanelStyles.element}>
        <FontAwesomeIcon
          className={elementListPanelStyles.deleteElementIcon}
          icon="times"
          onClick={() => handleRemove(id)}
        />
        <div className={elementListPanelStyles.elementIconContainer}>
          <img
            className={elementListPanelStyles.elementIcon}
            src={variable_distributed_load_icon}
            alt="Load"
          />
          <div>{varName}</div>
        </div>
        <div className={elementListPanelStyles.elementInput}>
          <div className={elementListPanelStyles.elementInputGrid}>
            <label
              htmlFor="distance1"
              className={elementListPanelStyles.elementInputLabel}
            >
              d1
            </label>
            <div className={elementListPanelStyles.equal}>=</div>
            <input
              className={elementListPanelStyles.elementInputBox}
              required
              name="distance1"
              id="distance1"
              value={inputLocation}
              onChange={(event) => handleDistanceInput(event, id, type)}
            ></input>
            <div className={elementListPanelStyles.unit}>{unitSystem[2]}</div>
            <label
              htmlFor="distance2"
              className={elementListPanelStyles.elementInputLabel}
            >
              d2
            </label>
            <div className={elementListPanelStyles.equal}>=</div>
            <input
              className={elementListPanelStyles.elementInputBox}
              required
              name="distance2"
              id="distance2"
              value={inputLocation2}
              onChange={(event) => handleDistanceInput2(event, id, type)}
            ></input>
            <div className={elementListPanelStyles.unit}>{unitSystem[2]}</div>
            <label
              htmlFor="magnitude"
              className={elementListPanelStyles.elementInputLabel}
            >
              I1
            </label>
            <div className={elementListPanelStyles.equal}>=</div>
            <input
              className={elementListPanelStyles.elementInputBox}
              required
              name="magnitude"
              id="magnitude"
              value={inputMagnitude}
              onChange={(event) => handleMagnitudeInput(event, id, type)}
            ></input>
            <div className={elementListPanelStyles.unit}>
              {unitSystem[0]}/{unitSystem[2]}
            </div>
            <label
              htmlFor="magnitude2"
              className={elementListPanelStyles.elementInputLabel}
            >
              I2
            </label>
            <div className={elementListPanelStyles.equal}>=</div>
            <input
              className={elementListPanelStyles.elementInputBox}
              required
              name="magnitude2"
              id="magnitude2"
              value={inputMagnitude2}
              onChange={(event) => handleMagnitudeInput2(event, id, type)}
            ></input>
            <div className={elementListPanelStyles.unit}>
              {unitSystem[0]}/{unitSystem[2]}
            </div>
          </div>
        </div>
      </div>
      <div className={elementListPanelStyles.elementListInputError}>
        {error}
      </div>
    </div>
  );
};

const Moment = ({
  inputLocation,
  handleDistanceInput,
  inputMagnitude,
  handleMagnitudeInput,
  handleRemove,
  id,
  error,
  type,
  varName,
  unitSystem,
}) => {
  return (
    <div className={elementListPanelStyles.elementBox}>
      <div className={elementListPanelStyles.element}>
        <FontAwesomeIcon
          className={elementListPanelStyles.deleteElementIcon}
          icon="times"
          onClick={() => handleRemove(id)}
        />
        <div className={elementListPanelStyles.elementIconContainer}>
          <img
            className={elementListPanelStyles.elementIcon}
            src={moment_icon}
            alt="Load"
          />
          <div>{varName}</div>
        </div>
        <div className={elementListPanelStyles.elementInput}>
          <div className={elementListPanelStyles.elementInputGrid}>
            <label
              htmlFor="distance1"
              className={elementListPanelStyles.elementInputLabel}
            >
              d
            </label>
            <div className={elementListPanelStyles.equal}>=</div>
            <input
              className={elementListPanelStyles.elementInputBox}
              required
              name="distance1"
              id="distance1"
              value={inputLocation}
              onChange={(event) => handleDistanceInput(event, id, type)}
            ></input>
            <div className={elementListPanelStyles.unit}>{unitSystem[2]}</div>
            <label
              htmlFor="magnitude"
              className={elementListPanelStyles.elementInputLabel}
            >
              M
            </label>
            <div className={elementListPanelStyles.equal}>=</div>
            <input
              className={elementListPanelStyles.elementInputBox}
              required
              name="magnitude"
              id="magnitude"
              value={inputMagnitude}
              onChange={(event) => handleMagnitudeInput(event, id, type)}
            ></input>
            <div className={elementListPanelStyles.unit}>
              {unitSystem[0]}⋅{unitSystem[2]}
            </div>
          </div>
        </div>
      </div>
      <div className={elementListPanelStyles.elementListInputError}>
        {error}
      </div>
    </div>
  );
};

const Support = ({
  inputLocation,
  handleDistanceInput,
  id,
  type,
  unitSystem,
}) => {
  return (
    <div className={elementListPanelStyles.elementInput}>
      <div className={elementListPanelStyles.elementInputGrid}>
        <label
          htmlFor="distance1"
          className={elementListPanelStyles.elementInputLabel}
        >
          d
        </label>
        <div className={elementListPanelStyles.equal}>=</div>
        <input
          className={elementListPanelStyles.elementInputBox}
          required
          name="distance1"
          id="distance1"
          value={inputLocation}
          onChange={(event) => handleDistanceInput(event, id, type)}
        ></input>
        <div className={elementListPanelStyles.unit}>{unitSystem[2]}</div>
      </div>
    </div>
  );
};

const RollerSupport = ({
  inputLocation,
  handleDistanceInput,
  handleRemove,
  id,
  error,
  type,
  varName,
  unitSystem,
}) => {
  return (
    <div className={elementListPanelStyles.elementBox}>
      <div className={elementListPanelStyles.element}>
        <FontAwesomeIcon
          className={elementListPanelStyles.deleteElementIcon}
          icon="times"
          onClick={() => handleRemove(id)}
        />
        <div className={elementListPanelStyles.elementIconContainer}>
          <img
            className={elementListPanelStyles.elementIcon}
            src={roller_support_icon}
            alt="Load"
          />
          <div>{varName}</div>
        </div>
        <Support
          inputLocation={inputLocation}
          handleDistanceInput={handleDistanceInput}
          id={id}
          type={type}
          unitSystem={unitSystem}
        ></Support>
      </div>
      <div className={elementListPanelStyles.elementListInputError}>
        {error}
      </div>
    </div>
  );
};

const PinedSupport = ({
  inputLocation,
  handleDistanceInput,
  handleRemove,
  id,
  error,
  type,
  varName,
  unitSystem,
}) => {
  return (
    <div className={elementListPanelStyles.elementBox}>
      <div className={elementListPanelStyles.element}>
        <FontAwesomeIcon
          className={elementListPanelStyles.deleteElementIcon}
          icon="times"
          onClick={() => handleRemove(id)}
        />
        <div className={elementListPanelStyles.elementIconContainer}>
          <img
            className={elementListPanelStyles.elementIcon}
            src={pined_support_icon}
            alt="Load"
          />
          <div>{varName}</div>
        </div>
        <Support
          inputLocation={inputLocation}
          handleDistanceInput={handleDistanceInput}
          id={id}
          type={type}
          unitSystem={unitSystem}
        ></Support>
      </div>
      <div className={elementListPanelStyles.elementListInputError}>
        {error}
      </div>
    </div>
  );
};

const FixedSupport = ({
  inputLocation,
  handleDistanceInput,
  handleRemove,
  id,
  error,
  type,
  varName,
  unitSystem,
}) => {
  return (
    <div className={elementListPanelStyles.elementBox}>
      <div className={elementListPanelStyles.element}>
        <FontAwesomeIcon
          className={elementListPanelStyles.deleteElementIcon}
          icon="times"
          onClick={() => handleRemove(id)}
        />
        <div className={elementListPanelStyles.elementIconContainer}>
          <img
            className={elementListPanelStyles.elementIcon}
            src={fixed_support_icon}
            alt="Load"
          />
          <div>{varName}</div>
        </div>
        <Support
          inputLocation={inputLocation}
          handleDistanceInput={handleDistanceInput}
          id={id}
          type={type}
          unitSystem={unitSystem}
        ></Support>
      </div>
      <div className={elementListPanelStyles.elementListInputError}>
        {error}
      </div>
    </div>
  );
};

const Beam = ({
  length,
  handleLengthInput,
  error,
  elementTypeTranslation,
  unitSystem,
}) => {
  // const handleLengthInput = (event) => {
  //   setBeamLengthValue(event.target.value);
  // };
  return (
    <div className={elementListPanelStyles.elementBox}>
      <div className={elementListPanelStyles.element}>
        <div className={elementListPanelStyles.elementIconContainer}>
          <img
            className={elementListPanelStyles.elementIcon}
            src={beam_icon}
            alt="Load"
          />
          <div> {elementTypeTranslation["8"]} </div>
        </div>
        <div className={elementListPanelStyles.elementInput}>
          <div className={elementListPanelStyles.elementInputGrid}>
            <label
              htmlFor="length"
              className={elementListPanelStyles.elementInputLabel}
            >
              L
            </label>
            <div className={elementListPanelStyles.equal}>=</div>
            <input
              className={elementListPanelStyles.elementInputBox}
              required
              name="length"
              id="length"
              value={length}
              onChange={handleLengthInput}
            ></input>
            <div className={elementListPanelStyles.unit}>{unitSystem[2]}</div>
          </div>
        </div>
      </div>
      <div className={elementListPanelStyles.elementListInputError}>
        {error}
      </div>
    </div>
  );
};

export default function Element({
  selectedElement,
  id,
  handleRemove,
  handleInputChange,
  beamLengthValue,
  handleLengthInput,
  elementTypeTranslation,
  unitSystem,
}) {
  const [inputLocation, setInputLocation] = useState(selectedElement.location);
  const [inputLocation2, setInputLocation2] = useState(
    selectedElement.location2
  );
  const [inputMagnitude, setInputMagnitude] = useState(selectedElement.value);
  const [inputMagnitude2, setInputMagnitude2] = useState(
    selectedElement.value2
  );
  const type = selectedElement.content;
  const varName = selectedElement.varName;
  let error = selectedElement.error;

  const handleDistanceInput = (event, id, type) => {
    setInputLocation(event.target.value);
    handleInputChange(
      id,
      event.target.value,
      inputLocation2,
      inputMagnitude,
      inputMagnitude2,
      type
    );
  };
  const handleDistanceInput2 = (event, id, type) => {
    setInputLocation2(event.target.value);
    handleInputChange(
      id,
      inputLocation,
      event.target.value,
      inputMagnitude,
      inputMagnitude2,
      type
    );
  };
  const handleMagnitudeInput = (event, id, type) => {
    setInputMagnitude(event.target.value);
    handleInputChange(
      id,
      inputLocation,
      inputLocation2,
      event.target.value,
      inputMagnitude2,
      type
    );
  };
  const handleMagnitudeInput2 = (event, id, type) => {
    setInputMagnitude2(event.target.value);
    handleInputChange(
      id,
      inputLocation,
      inputLocation2,
      inputMagnitude,
      event.target.value,
      type
    );
  };

  return (
    <div>
      {(() => {
        switch (selectedElement.content) {
          case "roller_support":
            return (
              <RollerSupport
                inputLocation={inputLocation}
                handleDistanceInput={handleDistanceInput}
                handleRemove={handleRemove}
                id={id}
                error={error}
                type={type}
                varName={varName}
                unitSystem={unitSystem}
              />
            );
          case "pined_support":
            return (
              <PinedSupport
                inputLocation={inputLocation}
                handleDistanceInput={handleDistanceInput}
                handleRemove={handleRemove}
                id={id}
                error={error}
                type={type}
                varName={varName}
                unitSystem={unitSystem}
              />
            );
          case "fixed_support":
            return (
              <FixedSupport
                inputLocation={inputLocation}
                handleDistanceInput={handleDistanceInput}
                handleRemove={handleRemove}
                id={id}
                error={error}
                type={type}
                varName={varName}
                unitSystem={unitSystem}
              />
            );
          case "point_load":
            return (
              <PointLoad
                inputLocation={inputLocation}
                handleDistanceInput={handleDistanceInput}
                inputMagnitude={inputMagnitude}
                handleMagnitudeInput={handleMagnitudeInput}
                handleRemove={handleRemove}
                id={id}
                error={error}
                type={type}
                varName={varName}
                unitSystem={unitSystem}
              />
            );
          case "uniform_distributed_load":
            return (
              <UniformDistributedLoad
                inputLocation={inputLocation}
                handleDistanceInput={handleDistanceInput}
                inputLocation2={inputLocation2}
                handleDistanceInput2={handleDistanceInput2}
                inputMagnitude={inputMagnitude}
                handleMagnitudeInput={handleMagnitudeInput}
                handleRemove={handleRemove}
                id={id}
                error={error}
                type={type}
                varName={varName}
                unitSystem={unitSystem}
              />
            );
          case "variable_distributed_load":
            return (
              <VariableDistributedLoad
                inputLocation={inputLocation}
                handleDistanceInput={handleDistanceInput}
                inputLocation2={inputLocation2}
                handleDistanceInput2={handleDistanceInput2}
                inputMagnitude={inputMagnitude}
                handleMagnitudeInput={handleMagnitudeInput}
                inputMagnitude2={inputMagnitude2}
                handleMagnitudeInput2={handleMagnitudeInput2}
                handleRemove={handleRemove}
                id={id}
                error={error}
                type={type}
                varName={varName}
                unitSystem={unitSystem}
              />
            );
          case "moment":
            return (
              <Moment
                inputLocation={inputLocation}
                handleDistanceInput={handleDistanceInput}
                inputMagnitude={inputMagnitude}
                handleMagnitudeInput={handleMagnitudeInput}
                handleRemove={handleRemove}
                id={id}
                error={error}
                type={type}
                varName={varName}
                unitSystem={unitSystem}
              />
            );
          case "beam":
            // return <Beam length={selectedElement.length} setBeamLengthValue={setBeamLengthValue} error={error}/>;
            return (
              <Beam
                length={beamLengthValue}
                handleLengthInput={handleLengthInput}
                error={error}
                elementTypeTranslation={elementTypeTranslation}
                unitSystem={unitSystem}
              />
            );
          default:
            return "No element";
        }
      })()}
    </div>
  );
}
