import { useState, useEffect } from "react";
import modalStyles from "../CSS_Module/modal.module.css";
import simple_beam_icon from "../Element_Image/Beam_Options_Icons/simple_beam.png";
import cantilever_beam_icon from "../Element_Image/Beam_Options_Icons/cantilever_beam.png";
import overhanging_beam_icon from "../Element_Image/Beam_Options_Icons/overhanging_beam.png";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faTimes);

const BeamSelectionModal = ({
  isBeamSelectionActive,
  setIsBeamSelectionActive,
  elementList,
  setElementList,
  beamLengthValue,
  beamType,
  buttonName,
  modalHeader,
}) => {
  const [selectedBeam, setSelectedBeam] = useState("");
  const [isClicked, setIsClicked] = useState([false, false, false]);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (isClicked.includes(true)) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [isClicked]);

  if (!isBeamSelectionActive) return null;

  const handleBeamOptionClick = (event, optionSelected) => {
    switch (optionSelected) {
      case 1:
        setIsClicked([true, false, false]);
        break;
      case 2:
        setIsClicked([false, true, false]);
        break;
      case 3:
        setIsClicked([false, false, true]);
        break;
    }
    // checkIsSelected();
    setSelectedBeam(optionSelected);
  };

  const handleSelection = (event) => {
    event.preventDefault();
    const supportArray = ["roller_support", "pined_support", "fixed_support"];
    // const newList = elementList.map((item) =>{
    //     if (!supportArray.includes(item.content)){
    //         return item;
    //     }
    // })
    const newList = elementList.filter(
      (element) => !supportArray.includes(element.content)
    );

    const d = new Date();
    let timeKey = d.getTime();
    let randomKey = Math.floor(Math.random() * 800);
    let uniqueKey =
      "predefined" + timeKey.toString() + "w" + randomKey.toString() + "01";
    let uniqueKey2 =
      "predefined" + timeKey.toString() + "w" + randomKey.toString() + "02";

    if (selectedBeam == 1) {
      setElementList([
        ...newList,
        {
          content: "pined_support",
          location: "0",
          location2: "",
          value: "",
          value2: "",
          error: "",
          id: uniqueKey,
          varName: "S1",
        },
        {
          content: "pined_support",
          location: beamLengthValue,
          location2: "",
          value: "",
          value2: "",
          error: "",
          id: uniqueKey2,
          varName: "S2",
        },
      ]);
    } else if (selectedBeam == 2) {
      setElementList([
        ...newList,
        {
          content: "fixed_support",
          location: "0",
          location2: "",
          value: "",
          value2: "",
          error: "",
          id: uniqueKey,
          varName: "S1",
        },
      ]);
    } else if (selectedBeam == 3) {
      setElementList([
        ...newList,
        {
          content: "pined_support",
          location: String(
            Math.round(0.3 * Number(beamLengthValue) * 100) / 100
          ),
          location2: "",
          value: "",
          value2: "",
          error: "",
          id: uniqueKey,
          varName: "S1",
        },
        {
          content: "pined_support",
          location: String(
            Math.round(0.7 * Number(beamLengthValue) * 100) / 100
          ),
          location2: "",
          value: "",
          value2: "",
          error: "",
          id: uniqueKey2,
          varName: "S2",
        },
      ]);
    }

    setIsBeamSelectionActive(false);
    setIsClicked([false, false, false]);
    setIsActive(false);
  };

  return (
    <div className={modalStyles.blurBackground}>
      <div className={modalStyles.modalBox}>
        <div className={modalStyles.modalBanner}>
          <FontAwesomeIcon
            onClick={() => {
              setIsBeamSelectionActive(false);
              setIsClicked([false, false, false]);
              setIsActive(false);
            }}
            className={modalStyles.icon}
            icon="times"
          />
        </div>
        <form onSubmit={handleSelection}>
          <div className={modalStyles.modalBody}>
            <div className={modalStyles.formTitle}>{modalHeader["2"]}</div>
            <div className={modalStyles.beamOptionContainer}>
              <div
                id="beamOption1"
                className={
                  isClicked[0]
                    ? modalStyles.beamOptionClicked
                    : modalStyles.beamOption
                }
                onClick={(event) => handleBeamOptionClick(event, 1)}
              >
                <img
                  className={modalStyles.beamIcon}
                  src={simple_beam_icon}
                  alt="Load"
                />
                <div className={modalStyles.beamIconLabel}>{beamType["1"]}</div>
              </div>
              <div
                id="beamOption2"
                className={
                  isClicked[1]
                    ? modalStyles.beamOptionClicked
                    : modalStyles.beamOption
                }
                onClick={(event) => handleBeamOptionClick(event, 2)}
              >
                <img
                  className={modalStyles.beamIcon}
                  src={cantilever_beam_icon}
                  alt="Load"
                />
                <div className={modalStyles.beamIconLabel}>{beamType["2"]}</div>
              </div>
              <div
                id="beamOption3"
                className={
                  isClicked[2]
                    ? modalStyles.beamOptionClicked
                    : modalStyles.beamOption
                }
                onClick={(event) => handleBeamOptionClick(event, 3)}
              >
                <img
                  className={modalStyles.beamIcon}
                  src={overhanging_beam_icon}
                  alt="Load"
                />
                <div className={modalStyles.beamIconLabel}>{beamType["3"]}</div>
              </div>
            </div>
            <button
              className={
                isActive
                  ? modalStyles.formButton
                  : modalStyles.formButtonInactive
              }
            >
              {buttonName["3"]}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BeamSelectionModal;
