import { useState } from "react";
import modalStyles from "../CSS_Module/modal.module.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faExclamationTriangle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faTimes, faExclamationTriangle);

const PreCalculationErrorModal = ({
  isPreCalculationError,
  setIsPreCalculationError,
  preCalculationError,
  dataCheckErrorMsg,
}) => {
  if (!isPreCalculationError) return null;

  return (
    <div className={modalStyles.blurBackground}>
      <div className={modalStyles.modalBox}>
        <div className={modalStyles.modalBanner}>
          <FontAwesomeIcon
            onClick={() => {
              setIsPreCalculationError(false);
            }}
            className={modalStyles.icon}
            icon="times"
          />
        </div>
        <div className={modalStyles.modalBody}>
          <FontAwesomeIcon
            className={modalStyles.preCalculationErrorIcon}
            icon="exclamation-triangle"
          />
          <div className={modalStyles.preCalculationErrorTextBox}>
            <div className={modalStyles.preCalculationErrorMsg}>
              {dataCheckErrorMsg["9"]}
            </div>
            <div className={modalStyles.preCalculationErrorMainMsg}>
              {preCalculationError}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreCalculationErrorModal;
