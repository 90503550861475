import { parse, evaluate, unit, number, typeOf } from "mathjs";
import { useState } from "react";
import { useRef, useEffect } from "react";
import {
  drawArrow,
  drawArrowSymbol,
  drawPointLoad,
  drawRollerSupport,
  drawPinedSupport,
  drawUniformDistributedLoad,
  drawVariableDistributedLoad,
  drawMoment,
  drawDashedLine,
  drawSolidLine,
  drawRec,
  drawBeam,
  drawPlusSign,
  addText,
  drawGrid,
  drawMomentSymbol,
  detectCollision,
  drawDataPoint,
} from "./shear_and_moment_diagram_element.js";
import DataPoint from "./datapoint.js";
import DataPointList from "./datapointList.js";
import { type } from "@testing-library/user-event/dist/type/index.js";

function DrawShearDiagram(
  ctx,
  location,
  shear,
  beamLength,
  maxAbsShear,
  maxAbsShearDiff,
  shearDiagramAxis,
  shearDiagramStartY,
  shearPeakPointArray,
  unitSystem,
  plotStartX,
  plotWidth
) {
  let plotHeight = 200;
  const AxisY = shearDiagramAxis;
  drawGrid(
    ctx,
    plotStartX,
    shearDiagramStartY,
    plotWidth,
    plotHeight,
    AxisY,
    beamLength,
    plotStartX
  );
  drawSolidLine(
    ctx,
    plotStartX,
    shearDiagramAxis,
    plotStartX + plotWidth,
    shearDiagramAxis,
    "black",
    1
  );
  addText(
    ctx,
    plotStartX + 20,
    shearDiagramStartY + plotHeight - 10,
    "X (" + unitSystem[2] + ")",
    "horizontal",
    12
  );
  addText(
    ctx,
    plotStartX,
    shearDiagramStartY - 10,
    "F (" + unitSystem[0] + ")",
    "horizontal",
    12
  );
  plotHeight = 0.8 * plotHeight; // to create space so that the line will not touch the plot border
  ctx.save();
  ctx.lineWidth = 1;
  let preX = plotStartX;
  let preY = AxisY;
  for (let i = 0; i < location.length; i++) {
    ctx.beginPath();
    ctx.strokeStyle = "blue";
    ctx.moveTo(preX, preY);
    ctx.lineTo(
      plotStartX + plotWidth * (location[i][0] / beamLength),
      shearDiagramAxis - plotHeight * (shear[i][0] / maxAbsShearDiff)
    );
    ctx.lineTo(
      plotStartX + plotWidth * (location[i][1] / beamLength),
      shearDiagramAxis - plotHeight * (shear[i][1] / maxAbsShearDiff)
    );
    ctx.stroke();
    preX = plotStartX + plotWidth * (location[i][1] / beamLength);
    preY = shearDiagramAxis - plotHeight * (shear[i][1] / maxAbsShearDiff);
  }

  ctx.restore();
}

function DrawMomentDiagram(
  ctx,
  location,
  moment,
  beamLength,
  maxAbsMoment,
  maxAbsMomentDiff,
  momentDiagramAxis,
  momentDiagramStartY,
  momentPeakPointArray,
  unitSystem,
  plotStartX,
  plotWidth
) {
  let plotHeight = 200;
  const AxisY = momentDiagramAxis;
  drawGrid(
    ctx,
    plotStartX,
    momentDiagramStartY,
    plotWidth,
    plotHeight,
    AxisY,
    beamLength,
    plotStartX
  );
  drawSolidLine(
    ctx,
    plotStartX,
    momentDiagramAxis,
    plotStartX + plotWidth,
    momentDiagramAxis,
    "black",
    1
  );
  addText(
    ctx,
    plotStartX + 20,
    momentDiagramStartY + plotHeight - 10,
    "X (" + unitSystem[2] + ")",
    "horizontal",
    12
  );
  addText(
    ctx,
    plotStartX,
    momentDiagramStartY - 10,
    "M (" + unitSystem[0] + "⋅" + unitSystem[2] + ")",
    "horizontal",
    12
  );
  plotHeight = 0.8 * plotHeight;
  ctx.save();
  ctx.lineWidth = 1;
  let preX = plotStartX;
  let preY = AxisY;
  for (let i = 0; i < location.length; i++) {
    ctx.beginPath();
    ctx.strokeStyle = "blue";
    ctx.moveTo(preX, preY);
    ctx.lineTo(
      plotStartX + plotWidth * (location[i][0] / beamLength),
      momentDiagramAxis - plotHeight * (moment[i][0] / maxAbsMomentDiff)
    );
    ctx.lineTo(
      plotStartX + plotWidth * (location[i][1] / beamLength),
      momentDiagramAxis - plotHeight * (moment[i][1] / maxAbsMomentDiff)
    );
    ctx.stroke();
    preX = plotStartX + plotWidth * (location[i][1] / beamLength);
    preY = momentDiagramAxis - plotHeight * (moment[i][1] / maxAbsMomentDiff);
  }

  ctx.restore();
}

function reOrder(elementList) {
  let newList = [];
  let supportList = [];
  let distributed_load = [];
  let concentrated_load = [];

  elementList.map((element) => {
    if (
      element.content == "fixed_support" ||
      element.content == "pined_support" ||
      element.content == "roller_support"
    ) {
      supportList.push(element);
    } else if (element.content == "point_load" || element.content == "moment") {
      concentrated_load.push(element);
    } else if (
      element.content == "uniform_distributed_load" ||
      element.content == "variable_distributed_load"
    ) {
      distributed_load.push(element);
    } else {
      newList.push(element);
    }
  });

  newList = newList.concat(supportList, distributed_load, concentrated_load);
  return newList;
}

function DrawDiagram(
  context,
  beamLengthValue,
  elementList,
  solution,
  ShearMomentResult,
  criticalPoint,
  variableName,
  setDataPointList,
  reactionList,
  diagramType,
  unitSystem
) {
  let plotWidth = 0;
  let plotHeight = 0;
  let plotStartX = 0;
  let diagramLabel = 0;
  let diagramLabelX = 0;
  let beamPlotLength = 0;
  let positiveDirectionSymbolGap = 0;
  if (window.innerWidth <= 500) {
    plotWidth = window.innerWidth * 0.85 - 55;
    plotHeight = 200;
    plotStartX = 30;
    diagramLabel = 15;
    diagramLabelX = 15;
    beamPlotLength = plotWidth;
    positiveDirectionSymbolGap = 8;
  } else {
    plotWidth = 740;
    plotHeight = 200;
    plotStartX = 80;
    diagramLabel = 20;
    diagramLabelX = 15;
    beamPlotLength = plotWidth;
    positiveDirectionSymbolGap = 35;
  }
  const beamDiagramStartY = 10;
  const beamDiagramHeight = 180;
  const beamAxis = beamDiagramStartY + beamDiagramHeight / 2;
  const shearDiagramStartY = 230;
  let shearDiagramAxis = shearDiagramStartY + plotHeight / 2;
  const momentDiagramStartY = 470;
  let momentDiagramAxis = momentDiagramStartY + plotHeight / 2;
  const ShearMomentInput = ShearMomentResult;
  const shearLocationArray = [];
  const momentLocationArray = [];
  const shearValueArray = [];
  const momentValueArray = [];

  const shearPeakPointArray = [];
  const momentPeakPointArray = [];

  // Calculate the values of shear and moment
  for (let i = 0; i < ShearMomentInput.length; i++) {
    let start = ShearMomentInput[i]["start"];
    let end = ShearMomentInput[i]["end"];
    let shearFunc = ShearMomentInput[i]["shear"];
    let momentFunc = ShearMomentInput[i]["moment"];
    let step = Math.abs(end - start) / 40;
    let interLocation1 = start;
    let interLocation2 = start + step;

    for (let j = 0; j < 40; j++) {
      shearLocationArray.push([interLocation1, interLocation2]);
      let shear3 =
        Math.round(evaluate(shearFunc, { x: interLocation1 }) * 100) / 100;
      let shear4 =
        Math.round(evaluate(shearFunc, { x: interLocation2 }) * 100) / 100;

      momentLocationArray.push([interLocation1, interLocation2]);
      let moment3 =
        Math.round(evaluate(momentFunc, { x: interLocation1 }) * 100) / 100;
      let moment4 =
        Math.round(evaluate(momentFunc, { x: interLocation2 }) * 100) / 100;

      interLocation1 += step;
      interLocation2 += step;

      shearValueArray.push([shear3, shear4]);
      momentValueArray.push([moment3, moment4]);
    }

    let shear1 = Math.round(evaluate(shearFunc, { x: start }) * 100) / 100;
    let shear2 = Math.round(evaluate(shearFunc, { x: end }) * 100) / 100;
    shearPeakPointArray.push({ location: start, value: shear1, type: "shear" });
    shearPeakPointArray.push({ location: end, value: shear2, type: "shear" });
    let moment1 = Math.round(evaluate(momentFunc, { x: start }) * 100) / 100;
    let moment2 = Math.round(evaluate(momentFunc, { x: end }) * 100) / 100;
    momentPeakPointArray.push({
      location: start,
      value: moment1,
      type: "moment",
    });
    momentPeakPointArray.push({
      location: end,
      value: moment2,
      type: "moment",
    });
  }

  //Add crtical points
  for (let i = 0; i < ShearMomentInput.length; i++) {
      let start = ShearMomentInput[i]["start"];
      let end = ShearMomentInput[i]["end"];
      let shearFunc = ShearMomentInput[i]["shear"];
      let momentFunc = ShearMomentInput[i]["moment"];
      for (let j = 0; j < criticalPoint.length; j++) {
        let currentLocation = Number(criticalPoint[j]);
        if (currentLocation>start &&currentLocation<end){
          let shear6 = Math.round(evaluate(shearFunc, { x: currentLocation }) * 100) / 100;
          shearPeakPointArray.push({ location: currentLocation, value: shear6, type: "shear" });
          let moment6 = Math.round(evaluate(momentFunc, { x: currentLocation }) * 100) / 100;
          momentPeakPointArray.push({
            location: currentLocation,
            value: moment6,
            type: "moment",
          });
        }
      }  
    }

  let maxAbsShear = Math.abs(shearValueArray[0][0]);
  let maxShear = 0;
  let minShear = 0;
  let maxAbsShearDiff = 0;
  let maxAbsMoment = Math.abs(shearValueArray[0][0]);
  let maxMoment = 0;
  let minMoment = 0;
  let maxAbsMomentDiff = 0;

  for (let i = 0; i < shearValueArray.length; i++) {
    let shearLine = shearValueArray[i];
    for (let j = 0; j < shearLine.length; j++) {
      if (maxShear < shearLine[j]) {
        maxShear = shearLine[j];
      }
      if (minShear > shearLine[j]) {
        minShear = shearLine[j];
      }

      let currentValue = Math.abs(shearLine[j]);
      if (maxAbsShear < currentValue) {
        maxAbsShear = currentValue;
      }
    }
  }

  for (let i = 0; i < momentValueArray.length; i++) {
    let momentLine = momentValueArray[i];
    for (let j = 0; j < momentLine.length; j++) {
      if (maxMoment < momentLine[j]) {
        maxMoment = momentLine[j];
      }
      if (minMoment > momentLine[j]) {
        minMoment = momentLine[j];
      }

      let currentValue = Math.abs(momentLine[j]);
      if (maxAbsMoment < currentValue) {
        maxAbsMoment = currentValue;
      }
    }
  }

  maxAbsShearDiff = maxShear - minShear;
  maxAbsMomentDiff = maxMoment - minMoment;

  shearDiagramAxis =
    shearDiagramStartY +
    0.1 * plotHeight +
    (0.8 * plotHeight * maxShear) / maxAbsShearDiff;
  momentDiagramAxis =
    momentDiagramStartY +
    0.1 * plotHeight +
    (0.8 * plotHeight * maxMoment) / maxAbsMomentDiff;

  let joinedPeakPointArray = shearPeakPointArray.concat(momentPeakPointArray);
  let adjustedShearPeakPointArray = joinedPeakPointArray.map((point) => {
    if (point.type == "shear") {
      return calculatePointLocation(
        point.location,
        point.value,
        plotStartX,
        plotWidth,
        0.8 * plotHeight,
        beamLengthValue,
        shearDiagramAxis,
        maxAbsShearDiff
      );
    } else {
      return calculatePointLocation(
        point.location,
        point.value,
        plotStartX,
        plotWidth,
        0.8 * plotHeight,
        beamLengthValue,
        momentDiagramAxis,
        maxAbsMomentDiff
      );
    }
  });

  let cleanedPeakPointArray = adjustedShearPeakPointArray.filter(
    (point) => typeof point != "undefined"
  ); // clear all undefined points
  setDataPointList(cleanedPeakPointArray);
  DrawShearDiagram(
    context,
    shearLocationArray,
    shearValueArray,
    beamLengthValue,
    maxAbsShear,
    maxAbsShearDiff,
    shearDiagramAxis,
    shearDiagramStartY,
    shearPeakPointArray,
    unitSystem,
    plotStartX,
    plotWidth
  );
  DrawMomentDiagram(
    context,
    momentLocationArray,
    momentValueArray,
    beamLengthValue,
    maxAbsMoment,
    maxAbsMomentDiff,
    momentDiagramAxis,
    momentDiagramStartY,
    momentPeakPointArray,
    unitSystem,
    plotStartX,
    plotWidth
  );

  let all_circles = [];

  drawRec(
    context,
    plotStartX,
    beamDiagramStartY,
    plotWidth,
    beamDiagramHeight,
    "black",
    0.5
  ); //Reaction Diagram Outline
  // drawSolidLine(context, 0, 0, 900, 0, "black", 1); // line on the top of the canvas
  drawRec(
    context,
    plotStartX,
    shearDiagramStartY,
    plotWidth,
    plotHeight,
    "black",
    0.5
  );
  drawRec(
    context,
    plotStartX,
    momentDiagramStartY,
    plotWidth,
    plotHeight,
    "black",
    0.5
  );
  drawBeam(context, plotStartX, beamPlotLength, beamAxis);
  addText(
    context,
    plotStartX + 20,
    beamDiagramStartY + beamDiagramHeight - 10,
    "X (" + unitSystem[2] + ")",
    "horizontal",
    12
  );
  drawArrowSymbol(
    context,
    plotStartX + plotWidth + positiveDirectionSymbolGap,
    beamAxis - 20,
    270,
    2,
    "black"
  );
  drawArrowSymbol(
    context,
    plotStartX + plotWidth + positiveDirectionSymbolGap,
    350,
    90,
    2,
    "black"
  );
  drawMomentSymbol(
    context,
    plotStartX + plotWidth + positiveDirectionSymbolGap + 5,
    570,
    "black"
  );

  addText(
    context,
    diagramLabelX,
    beamAxis,
    diagramType["1"],
    "vertical",
    diagramLabel
  );
  addText(
    context,
    diagramLabelX,
    shearDiagramStartY + plotHeight / 2,
    diagramType["2"],
    "vertical",
    diagramLabel
  );
  addText(
    context,
    diagramLabelX,
    momentDiagramStartY + plotHeight / 2,
    diagramType["3"],
    "vertical",
    diagramLabel
  );

  let beamLength = beamLengthValue;
  var location1 = 0;
  var location2 = 0;
  var value = 0;
  var value2 = 0;
  let locationList = ["0", String(beamLengthValue)];

  let newList = reOrder(elementList);

  newList.map((element) => {
    if (!locationList.includes(element.location) && element.location !== "") {
      locationList.push(element.location);
    }
    if (!locationList.includes(element.location2) && element.location2 !== "") {
      locationList.push(element.location2);
    }

    location1 = plotStartX + plotWidth * (element.location / beamLength);
    location2 = plotStartX + plotWidth * (element.location2 / beamLength);
    value = element.value;
    value2 = element.value2;
    // addText(context, location1, 760, element.location, "horizontal");

    switch (element.content) {
      case "roller_support":
        drawRollerSupport(
          context,
          location1,
          beamAxis + 10,
          "rgb(190, 190, 190)"
        );
        break;
      case "pined_support":
        drawPinedSupport(
          context,
          location1,
          beamAxis + 10,
          "rgb(190, 190, 190)"
        );
        break;
      // case "fixed_support":
      //   return <FixedSupport inputLocation={inputLocation} handleDistanceInput={handleDistanceInput} handleRemove={handleRemove} id={id}/>;
      case "point_load":
        drawPointLoad(context, location1, beamAxis, value, "black", unitSystem);
        break;
      case "uniform_distributed_load":
        drawUniformDistributedLoad(
          context,
          location1,
          location2,
          beamAxis - 10,
          value,
          unitSystem
        );
        break;
      case "variable_distributed_load":
        drawVariableDistributedLoad(
          context,
          location1,
          location2,
          beamAxis - 10,
          value,
          value2,
          unitSystem
        );
        break;
      case "moment":
        drawMoment(context, location1, beamAxis, "black", value, unitSystem);
        break;
      // case "beam":
      //   return <Beam length={selectedElement.length} setBeamLengthValue={setBeamLengthValue}/>;
      default:
        return "No element";
    }
  });

  reactionList.map((element) => {
    location1 = plotStartX + plotWidth * (element.location / beamLength);
    value = element.value;

    switch (element.content) {
      case "point_load":
        drawPointLoad(context, location1, beamAxis, value, "blue", unitSystem);
        break;
      case "moment":
        drawMoment(context, location1, beamAxis, "blue", value, unitSystem);
        break;
      default:
        return "No element";
    }
  });

  let sortedList = locationList.sort(function (a, b) {
    return a - b;
  });
  let prev = "";
  let prevLocation = 0;
  let current = "";
  let currentLocation = 0;
  let yLocation = "Bottom";
  sortedList.map((locationToDraw) => {
    current = locationToDraw;
    let locationToAdd = plotStartX + plotWidth * (locationToDraw / beamLength);
    currentLocation = locationToAdd;
    drawDashedLine(
      context,
      locationToAdd,
      beamDiagramStartY,
      locationToAdd,
      beamDiagramStartY + beamDiagramHeight,
      [5, 5]
    );

    if (
      detectCollision(
        context,
        "12px Arial",
        prev,
        prevLocation,
        current,
        currentLocation
      ) &&
      yLocation == "Bottom"
    ) {
      addText(
        context,
        locationToAdd,
        beamDiagramStartY + beamDiagramHeight - 8,
        locationToDraw,
        "horizontal",
        12
      );
      yLocation = "Top";
    } else {
      addText(
        context,
        locationToAdd,
        beamDiagramStartY + beamDiagramHeight + 12,
        locationToDraw,
        "horizontal",
        12
      );
      yLocation = "Bottom";
    }
    prev = current;
    prevLocation = currentLocation;
  });
}

function calculatePointLocation(
  locationX,
  valueY,
  plotStartX,
  plotWidth,
  plotHeight,
  beamLength,
  axis,
  maxAbsDiff
) {
  let x = plotStartX + plotWidth * (locationX / beamLength);
  let y = axis - plotHeight * (valueY / maxAbsDiff);
  if (isNaN(y)) {
    return;
  } else {
    const d = new Date();
    let timeKey = d.getTime();
    let randomKey = Math.floor(Math.random() * 300);
    let randomKey2 = Math.floor(Math.random() * 100);
    let uniqueKey =
      timeKey.toString() +
      "data" +
      randomKey.toString() +
      "point" +
      randomKey2.toString();
    return { cx: x, cy: y, location: locationX, value: valueY, id: uniqueKey };
  }
}

export default function ShearAndMomentDiagram({
  beamLengthValue,
  elementList,
  solution,
  ShearMomentResult,
  criticalPoint,
  variableName,
  reactionList,
  diagramType,
  unitSystem,
}) {
  const canvasRef = useRef(null);
  const [dataPointList, setDataPointList] = useState([]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (window.innerWidth <= 500) {
      canvas.width = 0.85 * window.innerWidth;
      canvas.height = 730;
    } else {
      canvas.width = 900;
      canvas.height = 730;
    }

    const context = canvas.getContext("2d");

    DrawDiagram(
      context,
      beamLengthValue,
      elementList,
      solution,
      ShearMomentResult,
      criticalPoint,
      variableName,
      setDataPointList,
      reactionList,
      diagramType,
      unitSystem
    );
  }, []);

  return (
    <div>
      <canvas ref={canvasRef}></canvas>
      <DataPointList dataPointList={dataPointList}></DataPointList>
    </div>
  );
}
