import { useState, useEffect } from "react";
import modalStyles from "../CSS_Module/modal.module.css";
import i18n from "../i18nextService";
import { useTranslation } from "react-i18next";
import tutorialVideo from "../GIFs/beam_calculator_tutorial.gif";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faTimes);

const Tutorial = ({ isTutorialActive, setIsTutorialActive }) => {
  const { t } = useTranslation();
  const tutorial = t("tutorial");

  if (!isTutorialActive) return null;

  return (
    <div className={modalStyles.blurBackground}>
      <div className={modalStyles.tutorialModalBox}>
        <div className={modalStyles.modalBanner}>
          <FontAwesomeIcon
            onClick={() => {
              setIsTutorialActive(false);
            }}
            className={modalStyles.icon}
            icon="times"
          />
        </div>
        <div className={modalStyles.tutorialModalBody}>
          <div className={modalStyles.formTitle}>{tutorial["1"]}</div>
          <div className={modalStyles.tutorialNote}>{tutorial["2"]}</div>
          <div className={modalStyles.tutorial}>
            <img
              className={modalStyles.tutorialVideo}
              src={tutorialVideo}
              alt="loading..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tutorial;
