import React from "react";
import styles from "../style.module.css";
import contactStyles from "../CSS_Module/Sub_Page/contact.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import TermsWindow from "../Component/terms_window";

const Contact = () => {
  const [isTermsWindowActive, setIsTermsWindowActive] = useState(false);
  const { t } = useTranslation();
  const title = t("title");
  const link = t("link");
  const buttonName = t("buttonName");
  const contactForm = t("contactForm");
  return (
    <div className={styles.main}>
      <div className={styles.sectionTitleBox}>
        <div className={styles.titleBoxRow}>
          <h1 className={styles.title}>{title["3"]}</h1>
        </div>
      </div>
      <div className={styles.section}>
        <form
          className={contactStyles.contactForm}
          action="https://formsubmit.co/864368317da923636e0696275888115f"
          method="POST"
        >
          <div className={contactStyles.contactFormBody}>
            <div className={contactStyles.formTitle}>{contactForm["1"]}</div>

            <div className={contactStyles.contactFormGrid}>
              <label className={contactStyles.formInputLabel} htmlFor="name">
                {contactForm["2"]}:
              </label>
              <div className={contactStyles.formInputboxContainer}>
                <input
                  required
                  className={contactStyles.contactInputBox}
                  name="name"
                  id="name"
                ></input>
              </div>

              <label className={contactStyles.formInputLabel} htmlFor="email">
                {contactForm["3"]}:
              </label>
              <div className={contactStyles.formInputboxContainer}>
                <input
                  required
                  className={contactStyles.contactInputBox}
                  name="email"
                  id="email"
                ></input>
              </div>

              <label className={contactStyles.formInputLabel} htmlFor="subject">
                {contactForm["4"]}:
              </label>
              <div className={contactStyles.formInputboxContainer}>
                <input
                  required
                  className={contactStyles.contactInputBox}
                  name="subject"
                  id="subject"
                ></input>
              </div>
              <label className={contactStyles.formInputLabel} htmlFor="message">
                {contactForm["5"]}:
              </label>
              <div className={contactStyles.formInputboxContainer}>
                <textarea
                  required
                  className={contactStyles.contactInputArea}
                  name="message"
                  id="message"
                ></textarea>
              </div>
            </div>
            <div className={contactStyles.termsAgreementRow}>
              <input
                required
                className={contactStyles.termCheckBox}
                type="checkbox"
                id="terms"
                name="terms"
                value="terms & conditions agreed"
              ></input>
              <label for="terms">
                {contactForm["6"]}&nbsp;
                {/* I agree to the */}
                <a
                  className={contactStyles.termLink}
                  onClick={() => setIsTermsWindowActive(true)}
                >
                  {contactForm["7"]}
                  {/* Terms & Conditions */}
                </a>
              </label>
            </div>
          </div>
          <input type="hidden" name="_captcha" value="false"></input>{" "}
          {/* Disable reCAPTCHA _captcha */}
          <input
            type="hidden"
            name="_next"
            value="https://mesolver.com/thankyou.html"
          ></input>
          {/* Thank you page */}
          <div className={contactStyles.contactButtonContainer}>
            <button className={contactStyles.formButton}>
              {buttonName["7"]}
            </button>
            <div className={styles.link}>
              <Link style={{ textDecoration: "none" }} to="../">
                {link["1"]}
              </Link>
            </div>
          </div>
        </form>
      </div>
      <TermsWindow
        isTermsWindowActive={isTermsWindowActive}
        setIsTermsWindowActive={setIsTermsWindowActive}
        title={title}
      />
    </div>
  );
};

export default Contact;
