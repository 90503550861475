import { simplify, derivative } from "mathjs";

function compare(a, b) {
    if (a['location'] < b['location']) {
      return -1;
    } else if (a['location'] > b['location']) {
      return 1;
    }
    return 0;
  }
  
function compare2(a, b) {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }
    return 0;
  }

function solveShearAndMoment(solution, input, beamLength) {
    let solutionCounter = 0;
    let elementList = [];
    const sectionList = [];
    const checkPointX = []; // end points of each section
    for (let i = 0; i < input.length; i++){
        let element = {};        
        if (input[i].type == 'support' ){ 
            element['value'] = solution[solutionCounter];   
            solutionCounter++;   
            checkPointX.push(input[i].location);
        } else if (input[i].type == 'distributed_load' || input[i].type == 'variable_distributed_load'){
            if (input[i].type == 'distributed_load'){
                element['value'] = input[i].magnitude;
                element['from'] = input[i].from;
                element['to'] = input[i].to;
                element['forceDistribution'] = input[i].forceDistribution;
                checkPointX.push(input[i].from);
                checkPointX.push(input[i].to); 
            } else {
                element['value'] = input[i].magnitude;
                element['from'] = input[i].from;
                element['to'] = input[i].to;
                // element['forceDistribution'] = input[i].forceDistribution;
                element['slope'] = input[i].slope;
                element['intercept'] = input[i].intercept;
                element['forceDistribution'] = input[i].forceDistribution;
                element['forceDistribution2'] = input[i].forceDistribution2;
                checkPointX.push(input[i].from);
                checkPointX.push(input[i].to); 
            }

        } else if (input[i].type == 'fixed_support'){
            element['force_value'] = solution[solutionCounter];   
            solutionCounter++;
            element['moment_value'] = solution[solutionCounter];  
            solutionCounter++;   
            checkPointX.push(input[i].location);
        } else if (input[i].type == 'force'){
            element['value'] = input[i].magnitude;
            checkPointX.push(input[i].location);
        } else if (input[i].type == 'moment'){
            element['value'] = input[i].magnitude;
            checkPointX.push(input[i].location);
        } else {
            element['value'] = input[i].magnitude;
        }
        element['type'] = input[i].type; 
        element['location'] = input[i].location;        
        elementList.push(element);
    }


    if(!checkPointX.includes(Number(beamLength))){
        checkPointX.push(Number(beamLength));    //To add a line where the last elment is not at the end of the beam
    }

    elementList.sort(compare);
    checkPointX.sort(compare2);
    const end = beamLength;
    let prev=0;  
    for (let i = 0; i < checkPointX.length; i++){
        let section = {};
        let moment = '0';        
        section['start'] = prev;
        section['end'] = checkPointX[i];
        for (let j = 0; j < elementList.length; j++) {
            let element = elementList[j];
            if( element['type'] == 'moment' || element['type'] == 'support' || element['type'] == 'force' || element['type'] == 'fixed_support') {
                if(element['location']>=0 && element['location']<section['end']){
                    if(element['type'] == 'moment'){
                        moment += '-(' + element['value'] + ')';
                    } else if (element['type'] == 'fixed_support') {
                        moment += '+((x + ' + -element['location'] + ')' +  '(' + element['force_value'] + '))' + '-(' + element['moment_value'] + ')'; 
                    } else {
                        moment += '+((x + ' + -element['location'] + ')' +  '(' + element['value'] + '))';                    
                    }                    
                }
            } else if (element['type'] == 'distributed_load') {
                if (element['from']<section['end'] && element['to']>=section['end']){
                    moment += '+(((x + ' + -element['from'] + ')^2)' +  '(' + element['forceDistribution'] + '/2))';
                } else if (element['to']<section['end']) {
                    moment += '+((x + ' + -element['location'] + ')' +  '(' + element['value'] + '))';    
                }
            } else if (element['type'] == 'variable_distributed_load') {
                // if (element['from']<section['end'] && element['to']>=section['end']){
                //     moment += '+(((x + ' + -element['from'] + ')^3)' +  '(' + element['forceDistribution'] + '/6))';
                // } else if (element['to']<section['end']) {
                //     moment += '+((x + ' + -element['location'] + ')' +  '(' + element['value'] + '))';    
                // }

                let term0 = '(x-' + element['from'] + ')';
                let term1 = '(' + element['slope'] + '(' + term0 + '^3))/3';
                let term2 = '(' + element['intercept'] + '(' + term0 + '^2))/2';
                let term3 = '(' + element['slope'] + '(' + term0 + '^2))/2';
                let term4 = '(' + element['intercept'] + term0 + ')';
                let xCentroid = simplify(term0 + '-((' + term1 + '+' + term2 + ')/(' + term3 + '+' + term4 + '))');

                if (element['from']<section['end'] && element['to']>=section['end']){
                    moment += '+((' + element['slope'] + ')' + '((x + ' + -element['from'] + ')^2)/2' + '+(' + element['intercept'] + ')' + '((x + ' + -element['from'] + ')))' + '(' + xCentroid + ')';
                } else if (element['to']<section['end']) {
                    moment += '+((x + ' + -element['location'] + ')' +  '(' + element['value'] + '))';    
                }
            } else {
                moment = '0';  // To force the moment function to be zero when there is no element at the last section of the beam
            }
        }
        section['shear'] = derivative(moment, 'x').toString();        
        section['moment'] = simplify(moment).toString();
        sectionList.push(section);        
        prev = checkPointX[i];     
    }
    return sectionList;
}


export { solveShearAndMoment };