import styles from "../style.module.css";
import toolbarStyles from "../CSS_Module/Beam_Calculator/toolbar.module.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faBars,
  faCheckSquare,
  faCoffee,
  faPlus,
  faSliders,
  faUndo,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(fab, faUndo, faPlus, faBars, faSliders, faPlay);

const Toolbar = ({
  setIsActive,
  setIsSubmitted,
  setIsBeamSelectionActive,
  setIsSettingActive,
  elementList,
  setIsPreCalculationError,
  setPreCalculationError,
  dataCheckErrorMsg,
}) => {
  const handleSimulation = () => {
    const supportList = ["roller_support", "pined_support", "fixed_support"];
    const LoadList = [
      "point_load",
      "moment",
      "uniform_distributed_load",
      "variable_distributed_load",
    ];

    let isError = true;
    let errorMsg = "";

    let errorList = [];
    let typeList = [];

    let supportCount = 0;
    let loadCount = 0;
    let fixedSupportCount = 0;

    elementList.map((element) => {
      errorList.push(element.error);
      typeList.push(element.content);
      if (supportList.includes(element.content)) {
        supportCount += 1;
        if (element.content == "fixed_support") {
          fixedSupportCount += 1;
        }
      } else if (LoadList.includes(element.content)) {
        loadCount += 1;
      }
    });

    if (elementList.length == 1) {
      errorMsg = dataCheckErrorMsg["1"]; //'No Input'
    } else if (elementList.length >= 8) {
      errorMsg = dataCheckErrorMsg["2"]; //'Max of 8 Inputs Allowed'
    } else {
      if (supportCount == 0 || loadCount == 0) {
        if (supportCount == 0) {
          errorMsg = dataCheckErrorMsg["3"]; //'No Support Defined'
        } else {
          errorMsg = dataCheckErrorMsg["4"]; //'No Load Defined'
        }
      } else {
        if (fixedSupportCount >= 2) {
          errorMsg = dataCheckErrorMsg["5"]; //'Only One Fixed Support Allowed'
        } else if (fixedSupportCount == 1 && supportCount >= 2) {
          errorMsg = dataCheckErrorMsg["6"]; //'The Beam Is Indeterminate'
        } else if (fixedSupportCount == 0 && supportCount == 1) {
          errorMsg = dataCheckErrorMsg["7"]; //'The Beam Is Unstable'
        } else {
          isError = false;
        }
      }
    }

    elementList.map((element) => {
      if (element.error != "") {
        isError = true;
        errorMsg = dataCheckErrorMsg["8"]; //'Invalid Input'
      }
    });

    if (!isError) {
      setIsSubmitted(true);
    } else {
      setPreCalculationError(errorMsg);
      setIsPreCalculationError(true);
    }
  };

  return (
    <div className={toolbarStyles.toolbar}>
      <div className={toolbarStyles.toolbarTop}>
        <button
          onClick={() => setIsBeamSelectionActive(true)}
          className={toolbarStyles.iconBox}
        >
          <FontAwesomeIcon className={toolbarStyles.icon} icon="bars" />
        </button>
        <button
          onClick={() => setIsActive(true)}
          className={toolbarStyles.iconBox}
        >
          <FontAwesomeIcon className={toolbarStyles.icon} icon="plus" />
        </button>
        <button
          onClick={() => handleSimulation()}
          className={toolbarStyles.iconBox}
        >
          <FontAwesomeIcon className={toolbarStyles.icon} icon="play" />
        </button>
      </div>
      <div className={toolbarStyles.toolbarBottom}>
        <button
          onClick={() => setIsSettingActive(true)}
          className={toolbarStyles.iconBox}
        >
          <FontAwesomeIcon className={toolbarStyles.icon} icon="sliders" />
        </button>
      </div>
    </div>
  );
};

export default Toolbar;
