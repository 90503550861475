import { evaluate, simplify, derivative } from "mathjs";


function newtonRaphson(f, df, x0, tol = 1e-7, maxIter = 100) {
  let currentX = x0;
  for (let i = 0; i < maxIter; i++) {
      let fx = evaluate(f, { x: currentX });
      let dfx = evaluate(df, { x: currentX });
      if (Math.abs(dfx) < tol) break;
      const x1 = currentX - fx / dfx;
      if (Math.abs(x1 - currentX) < tol) return x1;
      currentX = x1;
  }
  return null; // No solution found within the iteration limit
}

function criticalPointSolver(equationArray) {
  let peakPointLocationArray = [];
  let step = 0.1;
  let tolerance = 0.1;

  for(let i=0; i<equationArray.length; i++){
    if(equationArray[i]["shear"]!='0'){
      let start = equationArray[i]["start"];
      let end = equationArray[i]["end"];
      let func = equationArray[i]["shear"];
      let dfunc = derivative(func, 'x').toString(); 
      let root = Math.round(newtonRaphson(func, dfunc, start)*1000)/1000;

      if (root !== null && root > start && root < end && !peakPointLocationArray.includes(root.toFixed(6))) {
        peakPointLocationArray.push(root.toFixed(6));
    }
    }
  }

  return peakPointLocationArray;

}
export { criticalPointSolver };