import { useState, useEffect } from "react";
import ElementListPanel from "./Component/element_list";
import Header from "./Component/header";
import Toolbar from "./Component/toolbar";
import styles from "./style.module.css";
import consoleStyles from "./CSS_Module/Beam_Calculator/console.module.css";
import Modal from "./Component/new_element_input";
import Diagram from "./Component/beam_diagram";
import Result from "./Component/result";
import Footer from "./Component/footer";
import BeamSelectionModal from "./Component/beam_selection_modal";
import PreCalculationErrorModal from "./Component/pre-calculation_error";
import Settings from "./Component/settings";
import i18n from "./i18nextService";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import handleExampleClick from "./Component/example";
import Tutorial from "./Component/tutorial";
import DisplayCheck from "./Component/displaySizeCheck";
import { width } from "@fortawesome/free-brands-svg-icons/fa42Group";

function BeamCalculator() {
  const [element, setElement] = useState("");
  const [diagramElement, setDiagramElement] = useState("");
  const [diagramElementList, setDiagramElementList] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isBeamSelectionActive, setIsBeamSelectionActive] = useState(false);
  const [isSettingActive, setIsSettingActive] = useState(false);
  const [isTutorialActive, setIsTutorialActive] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedElement, setSelectedElement] = useState("pined_support");
  const [elementLocation, setLocation] = useState("");
  const [elementLocation2, setLocation2] = useState("");
  const [elementValue, setValue] = useState("");
  const [beamLengthValue, setBeamLengthValue] = useState("100");
  const initialValues = {
    type: "",
    distance1: "",
    distance2: "",
    magnitude: "",
    magnitude2: "",
    intensity: "",
  };
  const [elementInput, setElementInput] = useState(initialValues);
  const [errorMessage, setErrorMessage] = useState(initialValues);
  const [isPreCalculationError, setIsPreCalculationError] = useState(false);
  const [preCalculationError, setPreCalculationError] = useState("");
  const [unitSystem, setUnitSystem] = useState(["N", "kg", "m"]);
  const [currentUnit, setCurrentUnit] = useState("metric");
  const [isLanguageMenuActive, setIsLanguageMenuActive] = useState(false);
  const [elementList, setElementList] = useState([
    {
      content: "beam",
      location: "",
      location2: "",
      value: "",
      value2: "",
      error: "",
      id: "beamBody",
      length: beamLengthValue,
    },
  ]);

  let currentWinWidth = 0;
  let currentWinHeight = 0;

  if (window.innerWidth * 0.8 - 320 <= 600 && window.innerWidth > 500) {
    currentWinWidth = 600;
    currentWinHeight = 704;
  } else if (window.innerWidth <= 500) {
    currentWinWidth = window.innerWidth;
    currentWinHeight = 304;
  } else {
    currentWinWidth = window.innerWidth * 0.8 - 320;
    currentWinHeight = 704;
  }

  const [canvasSize, setCanvasSize] = useState({
    // width: window.innerWidth * 0.8 - 320,
    width: currentWinWidth,
    height: currentWinHeight,
  });

  const { t } = useTranslation();
  const elementTypeTranslation = t("elementType");
  const inputType = t("inputType");
  const beamType = t("beamType");
  const buttonName = t("buttonName");
  const header = t("header");
  const modalHeader = t("modalHeader");
  const diagramType = t("diagramType");
  const dataCheckErrorMsg = t("dataCheckErrorMsg");
  const footer = t("footer");
  const title = t("title");

  return (
    <div className="beamCalculator">
      <div className={styles.main}>
        <div className={styles.titleBox}>
          <div className={styles.titleBoxRow}>
            <h1 className={styles.title}>{title["1"]}</h1>
            <div className={styles.titleButtonContainer}>
              <button
                onClick={() => setIsTutorialActive(true)}
                className={styles.tutorialButton}
              >
                {buttonName["5"]} {/*Tutorial*/}
              </button>
              <button
                className={styles.tutorialButton}
                onClick={(event) =>
                  handleExampleClick(
                    event,
                    setElementList,
                    elementList,
                    beamLengthValue,
                    setBeamLengthValue
                  )
                }
              >
                {buttonName["6"]} {/*Example*/}
              </button>
            </div>
          </div>
        </div>

        <div className={consoleStyles.console}>
          <ElementListPanel
            elementList={elementList}
            setElementList={setElementList}
            beamLengthValue={beamLengthValue}
            setBeamLengthValue={setBeamLengthValue}
            elementTypeTranslation={elementTypeTranslation}
            unitSystem={unitSystem}
          />
          <Toolbar
            setIsActive={setIsActive}
            setIsSubmitted={setIsSubmitted}
            setIsBeamSelectionActive={setIsBeamSelectionActive}
            setIsSettingActive={setIsSettingActive}
            elementList={elementList}
            setIsPreCalculationError={setIsPreCalculationError}
            setPreCalculationError={setPreCalculationError}
            dataCheckErrorMsg={dataCheckErrorMsg}
          />
          <Diagram
            diagramElementList={diagramElementList}
            setDiagramElementList={setDiagramElementList}
            elementList={elementList}
            beamLengthValue={beamLengthValue}
            canvasSize={canvasSize}
            setCanvasSize={setCanvasSize}
            unitSystem={unitSystem}
          />
        </div>
        <div className={styles.link}>
          <Link style={{ textDecoration: "none" }} to="/terms">
            {footer["1"]}
          </Link>
        </div>
        <div className={styles.copyright}>
          © 2024 Mesolver, All Rights Reserved
        </div>
      </div>
      <Modal
        isActive={isActive}
        setIsActive={setIsActive}
        setElementList={setElementList}
        elementList={elementList}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
        elementLocation={elementLocation}
        setLocation={setLocation}
        elementLocation2={elementLocation2}
        setLocation2={setLocation2}
        elementValue={elementValue}
        setValue={setValue}
        diagramElement={diagramElement}
        setDiagramElement={setDiagramElement}
        diagramElementList={diagramElementList}
        setDiagramElementList={setDiagramElementList}
        beamLengthValue={beamLengthValue}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        elementInput={elementInput}
        setElementInput={setElementInput}
        unitSystem={unitSystem}
      />
      <BeamSelectionModal
        isBeamSelectionActive={isBeamSelectionActive}
        setIsBeamSelectionActive={setIsBeamSelectionActive}
        elementList={elementList}
        setElementList={setElementList}
        beamLengthValue={beamLengthValue}
        beamType={beamType}
        buttonName={buttonName}
        modalHeader={modalHeader}
      />

      <Settings
        isSettingActive={isSettingActive}
        setIsSettingActive={setIsSettingActive}
        setUnitSystem={setUnitSystem}
        currentUnit={currentUnit}
        setCurrentUnit={setCurrentUnit}
      />

      <Result
        isSubmitted={isSubmitted}
        setIsSubmitted={setIsSubmitted}
        elementList={elementList}
        beamLengthValue={beamLengthValue}
        diagramType={diagramType}
        unitSystem={unitSystem}
      />

      <PreCalculationErrorModal
        isPreCalculationError={isPreCalculationError}
        setIsPreCalculationError={setIsPreCalculationError}
        preCalculationError={preCalculationError}
        dataCheckErrorMsg={dataCheckErrorMsg}
      />

      <Tutorial
        isTutorialActive={isTutorialActive}
        setIsTutorialActive={setIsTutorialActive}
      />
      <DisplayCheck></DisplayCheck>
    </div>
  );
}

export default BeamCalculator;
