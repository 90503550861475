function Matrixify(input) {

    let variableCount = 0; 
    let supportCount = 0;
    const variableArray = [];
    const forceArray = [];
    const momentArray = [];

    //Determine the number of supports
    for (let i = 0; i < input.length; i++) {
        if (input[i].getType() == "support" || input[i].getType() == "fixed_support") {
            variableArray.push([variableCount, input[i].getLocation(),"reaction_at_support", input[i].getType()]);
            variableCount += 1;
            supportCount += 1;
            if (input[i].getType() == "fixed_support") {
                variableArray.push([variableCount, 1, "moment_at_fixed_support", input[i].getType()]);  
                variableCount += 1;
            }
        }
        else if (input[i].getType() == "force" || input[i].getType() == "distributed_load" || input[i].getType() == "variable_distributed_load") {
            forceArray.push([input[i].getMagnitude(), input[i].getLocation()]);
        }
        else if (input[i].getType() == "moment") {
            momentArray.push(input[i].getMagnitude());
        }
    }

    const coefficentMatrix = [];
    const constantMatrix = [];

    //Creat the first row for fy
    const f_y = [];
    let f_ytotal = 0;

    for (let j = 0; j < supportCount; j++) {
        if (variableArray[j][3] == "fixed_support"){  
            f_y.push(1);   
            f_y.push(0); 
        } else {
            f_y.push(1);              
        }
    }

    coefficentMatrix.push(f_y);
    for (let j = 0; j < input.length; j++) {
        if (input[j].getType() == "force" || input[j].getType() == "distributed_load" || input[j].getType() == "variable_distributed_load") {
            f_ytotal -= input[j].getMagnitude();
        }
    }
    constantMatrix.push(f_ytotal);

    // create the other rows for moments at each support
    for (let m = 0; m < variableCount-1; m++) {
        let pivot = variableArray[m]; //support
        let moment = 0;
        let coefficents = []; 
        let constants = 0; 
        for (let n = 0; n < variableCount; n++) {
            let current = variableArray[n];
            if (current[2] == "moment_at_fixed_support"){
                moment = 1;
            } else {
                if (pivot[1] < current[1]) {
                    moment = (current[1] - pivot[1]);
                }
                else if (pivot[1] == current[1]) {
                    moment = 0;
                }
                else if (pivot[1] > current [1]){
                    moment = pivot[1] - current[1];
                }
            }
            coefficents.push(moment);
        }
        coefficentMatrix.push(coefficents);
        moment = 0;
        for (let h = 0; h < forceArray.length; h++) {
            let current = forceArray[h];
            if (pivot[1] <= current[1]) {
                moment = -current[0]*(current[1] - pivot[1]);
            }
            else if (pivot[1] > current[1]){
                moment = current[0]*(pivot[1] - current[1]);
            }
            constants += moment;
        }

        if (momentArray.length!=0) {
            for (let g = 0; g < momentArray.length; g++) {
                constants += -momentArray[g];
            }
        }
        constantMatrix.push(constants);
    }
    // console.log(coefficentMatrix);



    const combo = [coefficentMatrix, constantMatrix];
    return combo;

}

export { Matrixify };