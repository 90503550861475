function drawArrow(ctx, x, y, angle, arrowLength, arrowWeight, color) {
  ctx.save();
  var headlen = 4;
  var angle = angle * (Math.PI / 180);
  ctx.strokeStyle = color;
  ctx.beginPath();
  ctx.moveTo(
    x - arrowLength * Math.cos(angle),
    y - arrowLength * Math.sin(angle)
  );
  ctx.lineTo(x, y);
  ctx.lineWidth = arrowWeight;
  ctx.stroke();
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(
    x - headlen * Math.cos(angle - Math.PI / 7),
    y - headlen * Math.sin(angle - Math.PI / 7)
  );

  ctx.lineTo(
    x - headlen * Math.cos(angle + Math.PI / 7),
    y - headlen * Math.sin(angle + Math.PI / 7)
  );

  ctx.lineTo(x, y);
  ctx.lineTo(
    x - headlen * Math.cos(angle - Math.PI / 7),
    y - headlen * Math.sin(angle - Math.PI / 7)
  );
  ctx.stroke();
  ctx.restore();
}

function drawArrowSymbol(ctx, x, y, angle, arrowWeight, color) {
  let arrowLength = 0;
  if (window.innerWidth <= 500) {
    arrowLength = 24;
  } else {
    arrowLength = 40;
  }
  ctx.save();
  var headlen = 4;
  var angle = angle * (Math.PI / 180);
  ctx.strokeStyle = color;
  ctx.beginPath();
  ctx.moveTo(
    x - arrowLength * Math.cos(angle),
    y - arrowLength * Math.sin(angle)
  );
  ctx.lineTo(x, y);
  ctx.lineWidth = arrowWeight;
  ctx.stroke();
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(
    x - headlen * Math.cos(angle - Math.PI / 7),
    y - headlen * Math.sin(angle - Math.PI / 7)
  );

  ctx.lineTo(
    x - headlen * Math.cos(angle + Math.PI / 7),
    y - headlen * Math.sin(angle + Math.PI / 7)
  );

  ctx.lineTo(x, y);
  ctx.lineTo(
    x - headlen * Math.cos(angle - Math.PI / 7),
    y - headlen * Math.sin(angle - Math.PI / 7)
  );
  ctx.stroke();
  ctx.restore();
  let gap = 0;
  if (window.innerWidth <= 500) {
    gap = 10;
  } else {
    gap = 15;
  }
  drawPlusSign(ctx, x + gap, y - (Math.sin(angle) * arrowLength) / 2);
}

function drawPointLoad(ctx, x, y, labelContent, color, unitSystem) {
  let height = 40;
  if (Number(labelContent) > 0) {
    drawArrow(ctx, x, y + 10, 270, height, 1.5, color);
    addText(
      ctx,
      x,
      y + height + 20,
      labelContent + " " + unitSystem[0],
      "horizontal",
      12,
      color
    );
  } else {
    drawArrow(ctx, x, y - 10, 90, height, 1.5, color);
    addText(
      ctx,
      x,
      y - height - 20,
      labelContent + " " + unitSystem[0],
      "horizontal",
      12,
      color
    );
  }
}

function drawRollerSupport(ctx, x, y, color) {
  ctx.save();
  ctx.lineWidth = 2;
  ctx.strokeStyle = color;
  let radius = (25 * 1.732) / 4;
  let groundLineLength = 50;
  ctx.beginPath();
  ctx.arc(x, y + radius, radius, 0, 2 * Math.PI);
  ctx.fillStyle = color;
  ctx.fill();
  ctx.stroke();
  ctx.beginPath();
  ctx.moveTo(x - groundLineLength / 2, y + radius * 2);
  ctx.lineTo(x + groundLineLength / 2, y + radius * 2);
  ctx.stroke();
  ctx.restore();
}

function drawPinedSupport(ctx, x, y, color) {
  ctx.save();
  ctx.lineWidth = 2;
  ctx.strokeStyle = color;
  let angle = 60 * (Math.PI / 180);
  let sideLength = 25;
  let groundLineLength = 50;
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(
    x - sideLength * Math.cos(angle),
    y + sideLength * Math.sin(angle)
  );
  ctx.lineTo(
    x + sideLength * Math.cos(angle),
    y + sideLength * Math.sin(angle)
  );
  ctx.lineTo(x, y);
  ctx.lineTo(
    x - sideLength * Math.cos(angle),
    y + sideLength * Math.sin(angle)
  );
  ctx.fillStyle = color;
  ctx.fill();
  ctx.stroke();
  ctx.beginPath();
  ctx.moveTo(x - groundLineLength / 2, y + sideLength * Math.sin(angle));
  ctx.lineTo(x + groundLineLength / 2, y + sideLength * Math.sin(angle));
  ctx.stroke();
  ctx.restore();
}

function drawFixedSupport(ctx, x, y, beamLeftEnd, beamRightEnd) {
  if (!isNaN(x) && !isNaN(y)) {
    let middlePoint = (beamRightEnd + beamLeftEnd) / 2;
    ctx.save();
    ctx.lineWidth = 2;
    let groundLineLength = 95;
    ctx.strokeStyle = "black";
    if (x < middlePoint) {
      const grd = ctx.createLinearGradient(x - 30, 0, x, 0);
      grd.addColorStop(1, "black");
      grd.addColorStop(0, "rgba(255, 255, 255, 0)");
      ctx.fillStyle = grd;
      ctx.fillRect(x - 30, y - groundLineLength / 2, 30, groundLineLength);
    } else {
      const grd = ctx.createLinearGradient(x, 0, x + 30, 0);
      grd.addColorStop(1, "rgba(255, 255, 255, 0)");
      grd.addColorStop(0, "black");
      ctx.fillStyle = grd;
      ctx.fillRect(x, y - groundLineLength / 2, 30, groundLineLength);
    }
    ctx.restore();
  }
}

function drawMoment(ctx, x, y, color, value, unitSystem) {
  ctx.save();
  ctx.strokeStyle = color;
  let radius = 15;
  let headlen = 5;

  if (value < 0) {
    let angle = 0.25 * Math.PI;
    ctx.beginPath();
    ctx.lineWidth = 1.5;
    ctx.arc(x, y, radius, 0.1 * 2 * Math.PI, 0.9 * 2 * Math.PI);
    ctx.stroke();
    let arrowX = x + radius * Math.cos(0.1 * 2 * Math.PI);
    let arrowY = y - radius * Math.sin(0.1 * 2 * Math.PI);

    ctx.beginPath();
    ctx.moveTo(arrowX, arrowY);
    ctx.lineTo(
      arrowX - headlen * Math.cos(angle - Math.PI / 7),
      arrowY - headlen * Math.sin(angle - Math.PI / 7)
    );

    ctx.lineTo(
      arrowX - headlen * Math.cos(angle + Math.PI / 7),
      arrowY - headlen * Math.sin(angle + Math.PI / 7)
    );

    ctx.lineTo(arrowX, arrowY);
    ctx.lineTo(
      arrowX - headlen * Math.cos(angle - Math.PI / 7),
      arrowY - headlen * Math.sin(angle - Math.PI / 7)
    );
    ctx.stroke();
  } else {
    let angle = 0.75 * Math.PI;
    ctx.beginPath();
    ctx.lineWidth = 1.5;
    ctx.arc(x, y, radius, 0.6 * 2 * Math.PI, 0.4 * 2 * Math.PI);
    ctx.stroke();
    let arrowX = x - radius * Math.cos(0.1 * 2 * Math.PI);
    let arrowY = y - radius * Math.sin(0.1 * 2 * Math.PI);

    ctx.beginPath();
    ctx.moveTo(arrowX, arrowY);
    ctx.lineTo(
      arrowX - headlen * Math.cos(angle - Math.PI / 7),
      arrowY - headlen * Math.sin(angle - Math.PI / 7)
    );

    ctx.lineTo(
      arrowX - headlen * Math.cos(angle + Math.PI / 7),
      arrowY - headlen * Math.sin(angle + Math.PI / 7)
    );

    ctx.lineTo(arrowX, arrowY);
    ctx.lineTo(
      arrowX - headlen * Math.cos(angle - Math.PI / 7),
      arrowY - headlen * Math.sin(angle - Math.PI / 7)
    );
    ctx.stroke();
  }

  ctx.beginPath();
  ctx.arc(x, y, 1, 0, 2 * Math.PI);
  ctx.fillStyle = "black";
  ctx.fill();
  ctx.stroke();
  addText(
    ctx,
    x,
    y + 25,
    value + " " + unitSystem[0] + "⋅" + unitSystem[2],
    "horizontal",
    12,
    color
  );
  ctx.restore();
}

function drawMomentSymbol(ctx, x, y, color) {
  ctx.save();
  ctx.strokeStyle = color;
  let radius = 0;
  if (window.innerWidth <= 500) {
    radius = 10;
  } else {
    radius = 20;
  }
  let headlen = 5;
  let angle = 0.75 * Math.PI;
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.arc(x, y, radius, 0.6 * 2 * Math.PI, 0.4 * 2 * Math.PI);
  ctx.stroke();
  let arrowX = x - radius * Math.cos(0.1 * 2 * Math.PI);
  let arrowY = y - radius * Math.sin(0.1 * 2 * Math.PI);

  ctx.beginPath();
  ctx.moveTo(arrowX, arrowY);
  ctx.lineTo(
    arrowX - headlen * Math.cos(angle - Math.PI / 7),
    arrowY - headlen * Math.sin(angle - Math.PI / 7)
  );

  ctx.lineTo(
    arrowX - headlen * Math.cos(angle + Math.PI / 7),
    arrowY - headlen * Math.sin(angle + Math.PI / 7)
  );

  ctx.lineTo(arrowX, arrowY);
  ctx.lineTo(
    arrowX - headlen * Math.cos(angle - Math.PI / 7),
    arrowY - headlen * Math.sin(angle - Math.PI / 7)
  );
  ctx.stroke();
  ctx.restore();
  drawPlusSign(ctx, x, y);
}

function drawUniformDistributedLoad(ctx, xFrom, xTo, y, value, unitSystem) {
  ctx.save();
  ctx.lineWidth = 2;
  let arrowCount = Math.round((xTo - xFrom) / 20);
  let gap = (xTo - xFrom) / (arrowCount - 1);
  ctx.strokeStyle = "rgb(130,200,54)";
  let height = 60;
  let xCenter = (xFrom + xTo) / 2;

  if (Number(value) < 0) {
    drawRecWithFill(
      ctx,
      xFrom,
      y,
      xTo - xFrom,
      height,
      "rgba(146,208,80,0.3)",
      0
    );
    drawArrow(ctx, xFrom, y, 90, height, 2, "rgb(130,200,54)");
    drawArrow(ctx, xTo, y, 90, height, 2, "rgb(130,200,54)");
    let x = xFrom;
    for (let i = 0; i < arrowCount - 2; i++) {
      x += gap;
      drawArrow(ctx, x, y, 90, 0, 2, "rgb(130,200,54)");
    }
    ctx.beginPath();
    ctx.moveTo(xFrom - 2, y - height);
    ctx.lineTo(xTo + 2, y - height);
    ctx.stroke();
    addText(
      ctx,
      xCenter,
      y - height - 5,
      value + " " + unitSystem[0] + "/" + unitSystem[2],
      "horizontal",
      12,
      "black"
    );
  } else {
    drawRecWithFill(
      ctx,
      xFrom,
      y + height + 20,
      xTo - xFrom,
      height,
      "rgba(146,208,80,0.3)",
      0
    );
    drawArrow(ctx, xFrom, y + 20, 270, height, 2, "rgb(130,200,54)");
    drawArrow(ctx, xTo, y + 20, 270, height, 2, "rgb(130,200,54)");
    let x = xFrom;
    for (let i = 0; i < arrowCount - 2; i++) {
      x += gap;
      drawArrow(ctx, x, y + 20, 270, 0, 2, "rgb(130,200,54)");
    }
    ctx.beginPath();
    ctx.moveTo(xFrom, y + height + 20);
    ctx.lineTo(xTo, y + height + 20);
    ctx.stroke();
    addText(
      ctx,
      xCenter,
      y + height + 10 + 20,
      value + " " + unitSystem[0] + "/" + unitSystem[2],
      "horizontal",
      12,
      "black"
    );
  }

  ctx.restore();
}

function drawVariableDistributedLoad(
  ctx,
  xFrom,
  xTo,
  y,
  value,
  value2,
  unitSystem
) {
  ctx.save();
  ctx.lineWidth = 2;
  ctx.strokeStyle = "rgb(187, 187, 187)";
  let height = 60;
  let arrowCount = Math.round((xTo - xFrom) / 30);
  let gap = (xTo - xFrom) / (arrowCount - 1);
  let maxValue = Math.max(Math.abs(value), Math.abs(value2));

  if (Number(value) < 0 || Number(value2) < 0) {
    drawArrow(
      ctx,
      xFrom,
      y,
      90,
      (-value / maxValue) * height,
      2,
      "rgb(187, 187, 187)"
    );
    drawArrow(
      ctx,
      xTo,
      y,
      90,
      (-value2 / maxValue) * height,
      2,
      "rgb(187, 187, 187)"
    );
    let x = xTo;

    for (let i = 0; i < arrowCount - 2; i++) {
      x -= gap;
      drawArrow(ctx, x, y, 90, 0, 2, "rgb(187, 187, 187)");
    }

    ctx.beginPath();
    ctx.fillStyle = "rgba(91, 155, 213, 0.3)";
    ctx.moveTo(xFrom, y + (value / maxValue) * height);
    ctx.lineTo(xTo, y + (value2 / maxValue) * height);
    ctx.stroke();
    ctx.lineTo(xTo, y);
    ctx.lineTo(xFrom, y);
    ctx.lineTo(xFrom, y - (-value / maxValue) * height);
    ctx.fill();

    addText(
      ctx,
      xFrom - 10,
      y - (-value / maxValue) * height - 5,
      value + " " + unitSystem[0] + "/" + unitSystem[2],
      "horizontal",
      12,
      "black"
    );
    addText(
      ctx,
      xTo + 10,
      y - (-value2 / maxValue) * height - 5,
      value2 + " " + unitSystem[0] + "/" + unitSystem[2],
      "horizontal",
      12,
      "black"
    );
  } else {
    drawArrow(
      ctx,
      xFrom,
      y + 20,
      270,
      (value / maxValue) * height,
      2,
      "rgb(187, 187, 187)"
    );
    drawArrow(
      ctx,
      xTo,
      y + 20,
      270,
      (value2 / maxValue) * height,
      2,
      "rgb(187, 187, 187)"
    );
    let x = xFrom;

    for (let i = 0; i < arrowCount - 2; i++) {
      x += gap;
      drawArrow(ctx, x, y + 20, 270, 0, 2, "rgb(187, 187, 187)");
    }
    ctx.beginPath();
    ctx.fillStyle = "rgba(91, 155, 213, 0.3)";
    ctx.moveTo(xFrom, y + (value / maxValue) * height + 20);
    ctx.lineTo(xTo, y + (value2 / maxValue) * height + 20);
    ctx.stroke();
    ctx.lineTo(xTo, y + 20);
    ctx.lineTo(xFrom, y + 20);
    ctx.lineTo(xFrom, y + (value / maxValue) * height);
    ctx.fill();

    addText(
      ctx,
      xFrom - 10,
      y + (value / maxValue) * height + 10 + 20,
      value + " " + unitSystem[0] + "/" + unitSystem[2],
      "horizontal",
      12,
      "black"
    );
    addText(
      ctx,
      xTo + 10,
      y + (value2 / maxValue) * height + 10 + 20,
      value2 + " " + unitSystem[0] + "/" + unitSystem[2],
      "horizontal",
      12,
      "black"
    );
  }
  ctx.restore();
}

function detectCollision(ctx, font, text1, text1Pos, text2, text2Pos) {
  ctx.save();
  ctx.font = font;
  let text1Width = ctx.measureText(text1).width;
  let text2Width = ctx.measureText(text2).width;
  let text2Left = text2Pos - text2Width / 2;
  let text1Right = text1Pos + text1Width / 2;
  ctx.restore();
  if (text2Left <= text1Right) {
    return true;
  } else {
    return false;
  }
}

function drawDashedLine(ctx, fromx, fromy, tox, toy, pattern) {
  ctx.save();
  ctx.strokeStyle = "grey";
  ctx.lineWidth = 0.5;
  ctx.beginPath();
  ctx.setLineDash(pattern);
  ctx.moveTo(fromx, fromy);
  ctx.lineTo(tox, toy);

  ctx.stroke();
  ctx.restore();
}

function drawSolidLine(ctx, fromx, fromy, tox, toy, color, lineWidth) {
  ctx.save();
  ctx.beginPath();
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = color;
  ctx.moveTo(fromx, fromy);
  ctx.lineTo(tox, toy);
  ctx.stroke();
  ctx.restore();
}

function drawRec(ctx, x, y, width, height, color, lineWidth) {
  ctx.save();
  ctx.beginPath();
  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = color;
  ctx.rect(x, y, width, height);
  ctx.stroke();
  ctx.restore();
}

function drawRecWithFill(ctx, x, y, width, height, color) {
  ctx.save();
  ctx.beginPath();
  ctx.fillStyle = color;
  ctx.fillRect(x, y - height, width, height);
  ctx.stroke();
  ctx.restore();
}

function drawBeam(ctx, x, length, y) {
  ctx.save();
  ctx.beginPath();
  ctx.fillStyle = "rgb(190, 190, 190)";
  ctx.fillRect(x, y - 10, length, 20);
  // ctx.strokeStyle = "black";
  // ctx.strokeRect(x, y-10, length, 20);
  ctx.stroke();
  ctx.restore();
}

function drawPlusSign(ctx, x, y) {
  ctx.save();
  ctx.beginPath();
  let size = 0;
  if (window.innerWidth <= 500) {
    size = 8;
  } else {
    size = 20;
  }
  ctx.lineWidth = 2;
  ctx.moveTo(x - size / 2, y);
  ctx.lineTo(x + size / 2, y);
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(x, y - size / 2);
  ctx.lineTo(x, y + size / 2);
  ctx.stroke();

  ctx.restore();
}

function addText(ctx, x, y, content, direction, size, color) {
  ctx.save();
  ctx.fillStyle = color;
  ctx.font = size + "px Arial";
  ctx.textBaseline = "middle";
  ctx.textAlign = "center";
  if (direction == "vertical") {
    ctx.translate(x, y);
    var angle = 270 * (Math.PI / 180);
    ctx.rotate(angle);
    ctx.fillText(content, 0, 0);
  } else {
    ctx.fillText(content, x, y);
  }
  ctx.restore();
}

function drawGrid(
  ctx,
  x,
  y,
  plotWidth,
  plotHeight,
  axisY,
  beamLengthValue,
  plotStartX
) {
  let beamLength = beamLengthValue;
  let originalBeamLength = beamLengthValue;
  let beamStep = 0;
  let multiplier = 10;

  if (beamLength >= 5) {
    beamStep = (beamLength - (beamLength % 5)) / 5;
  } else if (beamLength < 5 && beamLength > 1) {
    beamStep = (beamLength * 10 - ((beamLength * 10) % 5)) / (5 * 10);
  } else {
    while (beamLength < 1) {
      beamLength = beamLength * 10;
      multiplier = multiplier * 10;
    }
    beamStep =
      (originalBeamLength * multiplier -
        ((originalBeamLength * multiplier) % 5)) /
      (5 * multiplier);
  }

  let step = (beamStep / beamLengthValue) * plotWidth;
  // let step = plotWidth/5;
  let fineStep = step / 5;
  let currentX = x + step;
  let currentFineLineX = x + fineStep;
  let currentY = axisY;
  let xValue = beamStep;
  ctx.save();
  let counter = 0;
  while (currentX < x + plotWidth) {
    drawSolidLine(ctx, currentX, y, currentX, y + plotHeight, "grey", 2);
    addText(
      ctx,
      currentX,
      y + plotHeight + 12,
      Math.round(xValue * multiplier) / multiplier,
      "horizontal",
      12,
      "black"
    );
    currentX += step;
    xValue += beamStep;
    counter += 1;
  }

  addText(ctx, plotStartX, y + plotHeight + 12, "0", "horizontal", 12, "black");
  if (counter < 5) {
    addText(
      ctx,
      plotStartX + plotWidth,
      y + plotHeight + 12,
      beamLengthValue,
      "horizontal",
      12,
      "black"
    );
  }

  while (currentFineLineX < x + plotWidth) {
    drawSolidLine(
      ctx,
      currentFineLineX,
      y,
      currentFineLineX,
      y + plotHeight,
      "rgb(187, 187, 187)",
      1
    );
    currentFineLineX += fineStep;
  }

  while (currentY < y + plotHeight) {
    drawSolidLine(
      ctx,
      x,
      currentY,
      x + plotWidth,
      currentY,
      "rgb(187, 187, 187)",
      1
    );
    currentY += fineStep;
  }

  currentY = axisY - fineStep;
  while (currentY > y) {
    drawSolidLine(
      ctx,
      x,
      currentY,
      x + plotWidth,
      currentY,
      "rgb(187, 187, 187)",
      1
    );
    currentY -= fineStep;
  }

  ctx.restore();
}

function drawDataPoint(
  ctx,
  locationX,
  valueY,
  plotStartX,
  plotWidth,
  plotHeight,
  beamLength,
  axis,
  maxAbsDiff
) {
  ctx.save();
  let x = plotStartX + plotWidth * (locationX / beamLength);
  let y = axis - plotHeight * (valueY / maxAbsDiff);
  ctx.beginPath();
  ctx.arc(x, y, 3, 0, 2 * Math.PI);
  ctx.strokeStyle = "black";
  ctx.fillStyle = "black";
  ctx.fill();
  ctx.stroke();

  ctx.restore();
}

export {
  drawArrow,
  drawArrowSymbol,
  drawPointLoad,
  drawRollerSupport,
  drawPinedSupport,
  drawUniformDistributedLoad,
  drawVariableDistributedLoad,
  drawMoment,
  drawDashedLine,
  drawSolidLine,
  drawRec,
  drawBeam,
  drawPlusSign,
  addText,
  drawGrid,
  drawMomentSymbol,
  detectCollision,
  drawDataPoint,
};
