import modalStyles from "../CSS_Module/modal.module.css";
import { useEffect } from "react";
import { useState } from "react";

import i18n from "../i18nextService";
import { useTranslation } from "react-i18next";

import ElementList from "./element_list";
import ReactDOM from "react-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faBars,
  faCheckSquare,
  faCoffee,
  faPlus,
  faSliders,
  faTimes,
  faUndo,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faTimes);

const InputForm = ({
  type,
  elementLocation,
  handleDistanceInput,
  elementInput,
  handleElementInput,
  errorMessage,
  intensityFunc,
  unitSystem,
  inputType,
}) => {
  const supportArray = ["roller_support", "pined_support", "fixed_support"];
  const concentratedLoadArray = ["point_load", "moment"];
  const distributedLoadArray = [
    "uniform_distributed_load",
    "variable_distributed_load",
  ];

  elementInput.type = type;

  if (supportArray.includes(type)) {
    return (
      <div className={modalStyles.inputFormGrid}>
        <label className={modalStyles.formInputLabel} htmlFor="distance1">
          {inputType["2"]}:
        </label>
        <div className={modalStyles.formInputboxContainer}>
          <input
            required
            className={modalStyles.inputBox}
            name="distance1"
            id="distance1"
            value={elementInput.distance1}
            onChange={handleElementInput}
          ></input>
          <div className={modalStyles.formInputUnit}>
            <span> {unitSystem[2]} </span>
          </div>
        </div>
        <div className={modalStyles.errorSpacer}></div>
        <div className={modalStyles.error}>{errorMessage.distance1}</div>
      </div>
    );
  } else if (concentratedLoadArray.includes(type)) {
    if (type == "moment") {
      return (
        <div className={modalStyles.inputFormGrid}>
          <label className={modalStyles.formInputLabel} htmlFor="distance1">
            {inputType["2"]}:
          </label>
          <div className={modalStyles.formInputboxContainer}>
            <input
              required
              className={modalStyles.inputBox}
              name="distance1"
              id="distance1"
              value={elementInput.distance1}
              onChange={handleElementInput}
            ></input>
            <div className={modalStyles.formInputUnit}>
              <span> {unitSystem[2]} </span>
            </div>
          </div>
          <div className={modalStyles.errorSpacer}></div>
          <div className={modalStyles.error}>{errorMessage.distance1}</div>
          <label className={modalStyles.formInputLabel} htmlFor="magnitude">
            {inputType["3"]}:
          </label>
          <div className={modalStyles.formInputboxContainer}>
            <input
              required
              className={modalStyles.inputBox}
              name="magnitude"
              id="magnitude"
              value={elementInput.magnitude}
              onChange={handleElementInput}
            ></input>
            <div className={modalStyles.formInputUnit}>
              <span>
                {" "}
                {unitSystem[0]}⋅{unitSystem[2]}{" "}
              </span>
            </div>
          </div>
          <div className={modalStyles.errorSpacer}></div>
          <div className={modalStyles.error}>{errorMessage.magnitude}</div>
        </div>
      );
    } else {
      return (
        <div className={modalStyles.inputFormGrid}>
          <label className={modalStyles.formInputLabel} htmlFor="distance1">
            {inputType["2"]}:
          </label>
          <div className={modalStyles.formInputboxContainer}>
            <input
              required
              className={modalStyles.inputBox}
              name="distance1"
              id="distance1"
              value={elementInput.distance1}
              onChange={handleElementInput}
            ></input>
            <div className={modalStyles.formInputUnit}>
              <span> {unitSystem[2]} </span>
            </div>
          </div>
          <div className={modalStyles.errorSpacer}></div>
          <div className={modalStyles.error}>{errorMessage.distance1}</div>
          <label className={modalStyles.formInputLabel} htmlFor="magnitude">
            {inputType["3"]}:
          </label>
          <div className={modalStyles.formInputboxContainer}>
            <input
              required
              className={modalStyles.inputBox}
              name="magnitude"
              id="magnitude"
              value={elementInput.magnitude}
              onChange={handleElementInput}
            ></input>
            <div className={modalStyles.formInputUnit}>
              <span> {unitSystem[0]} </span>
            </div>
          </div>
          <div className={modalStyles.errorSpacer}></div>
          <div className={modalStyles.error}>{errorMessage.magnitude}</div>
        </div>
      );
    }
  } else if (distributedLoadArray.includes(type)) {
    if (type == "variable_distributed_load") {
      return (
        <div className={modalStyles.inputFormGrid}>
          <label className={modalStyles.formInputLabel} htmlFor="distance1">
            {inputType["4"]}:
          </label>
          <div className={modalStyles.formInputboxContainer}>
            <input
              required
              className={modalStyles.inputBox}
              name="distance1"
              id="distance1"
              value={elementInput.distance1}
              onChange={handleElementInput}
            ></input>
            <div className={modalStyles.formInputUnit}>
              <span> {unitSystem[2]} </span>
            </div>
          </div>
          <div className={modalStyles.errorSpacer}></div>
          <div className={modalStyles.error}>{errorMessage.distance1}</div>
          <label className={modalStyles.formInputLabel} htmlFor="distance2">
            {inputType["5"]}:
          </label>
          <div className={modalStyles.formInputboxContainer}>
            <input
              required
              className={modalStyles.inputBox}
              name="distance2"
              id="distance2"
              value={elementInput.distance2}
              onChange={handleElementInput}
            ></input>
            <div className={modalStyles.formInputUnit}>
              <span> {unitSystem[2]} </span>
            </div>
          </div>
          <div className={modalStyles.errorSpacer}></div>
          <div className={modalStyles.error}>{errorMessage.distance2}</div>
          <label className={modalStyles.formInputLabel} htmlFor="magnitude">
            {inputType["8"]}:
          </label>
          <div className={modalStyles.formInputboxContainer}>
            <input
              required
              className={modalStyles.inputBox}
              name="magnitude"
              id="magnitude"
              value={elementInput.magnitude}
              onChange={handleElementInput}
            ></input>
            <div className={modalStyles.formInputUnit}>
              <span>
                {unitSystem[0]}/{unitSystem[2]}
              </span>
            </div>
          </div>
          <div className={modalStyles.errorSpacer}></div>
          <div className={modalStyles.error}>{errorMessage.magnitude}</div>
          <label className={modalStyles.formInputLabel} htmlFor="magnitude2">
            {inputType["9"]}:
          </label>
          <div className={modalStyles.formInputboxContainer}>
            <input
              required
              className={modalStyles.inputBox}
              name="magnitude2"
              id="magnitude2"
              value={elementInput.magnitude2}
              onChange={handleElementInput}
            ></input>
            <div className={modalStyles.formInputUnit}>
              <span>
                {unitSystem[0]}/{unitSystem[2]}
              </span>
            </div>
          </div>
          <div className={modalStyles.errorSpacer}></div>
          <div className={modalStyles.error}>{errorMessage.magnitude2}</div>
        </div>
      );
    } else {
      return (
        <div className={modalStyles.inputFormGrid}>
          <label className={modalStyles.formInputLabel} htmlFor="distance1">
            {inputType["4"]}:
          </label>
          <div className={modalStyles.formInputboxContainer}>
            <input
              required
              className={modalStyles.inputBox}
              name="distance1"
              id="distance1"
              value={elementInput.distance1}
              onChange={handleElementInput}
            ></input>
            <div className={modalStyles.formInputUnit}>
              <span> {unitSystem[2]} </span>
            </div>
          </div>
          <div className={modalStyles.errorSpacer}></div>
          <div className={modalStyles.error}>{errorMessage.distance1}</div>
          <label className={modalStyles.formInputLabel} htmlFor="distance2">
            {inputType["5"]}:
          </label>
          <div className={modalStyles.formInputboxContainer}>
            <input
              required
              className={modalStyles.inputBox}
              name="distance2"
              id="distance2"
              value={elementInput.distance2}
              onChange={handleElementInput}
            ></input>
            <div className={modalStyles.formInputUnit}>
              <span> {unitSystem[2]} </span>
            </div>
          </div>
          <div className={modalStyles.errorSpacer}></div>
          <div className={modalStyles.error}>{errorMessage.distance2}</div>
          <label className={modalStyles.formInputLabel} htmlFor="magnitude">
            {inputType["6"]}:
          </label>
          <div className={modalStyles.formInputboxContainer}>
            <input
              required
              className={modalStyles.inputBox}
              name="magnitude"
              id="magnitude"
              value={elementInput.magnitude}
              onChange={handleElementInput}
            ></input>
            <div className={modalStyles.formInputUnit}>
              <span>
                {" "}
                {unitSystem[0]}/{unitSystem[2]}{" "}
              </span>
            </div>
          </div>
          <div className={modalStyles.errorSpacer}></div>
          <div className={modalStyles.error}>{errorMessage.magnitude}</div>
        </div>
      );
    }
  } else {
    return "Type Unidentified";
  }
};

const Modal = ({
  isActive,
  setIsActive,
  elementList,
  setElementList,
  selectedElement,
  setSelectedElement,
  elementLocation,
  setLocation,
  elementLocation2,
  setLocation2,
  elementValue,
  setValue,
  diagramElement,
  setDiagramElement,
  diagramElementList,
  setDiagramElementList,
  beamLengthValue,
  errorMessage,
  setErrorMessage,
  elementInput,
  setElementInput,
  unitSystem,
}) => {
  const { t } = useTranslation();
  const elementType = t("elementType");
  const inputType = t("inputType");
  const errorMessageTranslation = t("errorMessage");

  const [intensityFunc, setIntensityFunc] = useState(" f(x) = A x");
  const [isButtonActive, setIsButtonActive] = useState(false);

  const validate = (input) => {
    let error = {};
    let type = input.type;
    let distance1 = input.distance1;
    let distance2 = input.distance2;
    let magnitude = input.magnitude;
    let magnitude2 = input.magnitude2;
    // let intensity = Number(input.intensity);

    if (!isNaN(distance1) && !String(distance1).includes(" ")) {
      if (Number(distance1) < 0) {
        error.distance1 = errorMessageTranslation["1"]; //'Location cannot be negative'
      } else if (Number(distance1) > Number(beamLengthValue)) {
        error.distance1 = errorMessageTranslation["2"]; //'Location is not on the beam'
      }
    } else if (String(distance1).includes(" ")) {
      error.distance1 = errorMessageTranslation["4"]; //'No space is allowed'
    } else {
      error.distance1 = errorMessageTranslation["3"]; //'Input is not a number'
    }

    if (!isNaN(distance2) && !String(distance2).includes(" ")) {
      if (Number(distance2) < 0) {
        error.distance2 = errorMessageTranslation["1"]; //'Location cannot be negative'
      } else if (Number(distance2) > Number(beamLengthValue)) {
        error.distance2 = errorMessageTranslation["2"]; //'Location is not on the beam'
      } else if (
        !String(distance1).length == 0 &&
        !isNaN(distance1) &&
        !String(distance2).length == 0 &&
        Number(distance2) < Number(distance1)
      ) {
        error.distance2 = errorMessageTranslation["5"]; //'Rule: To > From'
      }
    } else if (String(distance2).includes(" ")) {
      error.distance2 = errorMessageTranslation["4"]; //'No space is allowed'
    } else {
      error.distance2 = errorMessageTranslation["3"]; //'Input is not a number'
    }

    if (!isNaN(magnitude) && !String(magnitude).includes(" ")) {
      if (
        Number(magnitude) == 0 &&
        magnitude != "" &&
        magnitude2 == "" &&
        type != "variable_distributed_load"
      ) {
        error.magnitude = errorMessageTranslation["6"]; //'The magnitude cannot be 0'
      }
    } else if (String(magnitude).includes(" ")) {
      error.magnitude = errorMessageTranslation["4"]; //'No space is allowed'
    } else {
      error.magnitude = errorMessageTranslation["3"]; //'Input is not a number'
    }

    if (!isNaN(magnitude2) && !String(magnitude2).includes(" ")) {
      if (
        Number(magnitude) == 0 &&
        Number(magnitude2) == 0 &&
        magnitude != "" &&
        magnitude2 != ""
      ) {
        error.magnitude2 = errorMessageTranslation["7"]; // 'Mag must not both be 0'
      }
    } else if (String(magnitude2).includes(" ")) {
      error.magnitude2 = errorMessageTranslation["4"]; //'No space is allowed'
    } else {
      error.magnitude2 = errorMessageTranslation["3"]; //'Input is not a number'
    }

    // if (!isNaN(intensity)){
    // } else {
    //   error.intensity = 'Input is not a number';
    // }

    if (Object.keys(error).length === 0) {
      setIsButtonActive(true);
    } else {
      setIsButtonActive(false);
    }

    return error;
  };

  useEffect(() => {
    setErrorMessage(validate(elementInput));
  }, [elementInput]);

  if (!isActive) return null;

  function assignVarName(type) {
    let variableName = "";
    let counter = [1, 1, 1, 1]; //[support, force, moment, distributed_load]

    const supportArray = ["roller_support", "pined_support", "fixed_support"];
    // const concentratedLoadArray = ["point_load", "moment"];
    const distributedLoadArray = [
      "uniform_distributed_load",
      "variable_distributed_load",
    ];

    elementList.map((item) => {
      if (supportArray.includes(item.content)) {
        counter[0] += 1;
      } else if (distributedLoadArray.includes(item.content)) {
        counter[3] += 1;
      } else if (item.content == "point_load") {
        counter[1] += 1;
      } else if (item.content == "moment") {
        counter[2] += 1;
      }
    });

    if (supportArray.includes(type)) {
      variableName = "S" + counter[0];
    } else if (distributedLoadArray.includes(type)) {
      variableName = "L" + counter[3];
    } else if (type == "point_load") {
      variableName = "F" + counter[1];
    } else if (type == "moment") {
      variableName = "M" + counter[2];
    } else {
      variableName = "Undefined";
    }

    return variableName;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const d = new Date();
    let timeKey = d.getTime();
    let randomKey = Math.floor(Math.random() * 800);
    let uniqueKey = timeKey.toString() + "w" + randomKey.toString();
    setElementList([
      ...elementList,
      {
        content: selectedElement,
        location: elementInput.distance1,
        location2: elementInput.distance2,
        value: elementInput.magnitude,
        value2: elementInput.magnitude2,
        error: "",
        id: uniqueKey,
        varName: assignVarName(selectedElement),
      },
    ]);
    setElementInput({
      type: "",
      distance1: "",
      distance2: "",
      magnitude: "",
      magnitude2: "",
      intensity: "",
    });
    setIntensityFunc(" f(x) = A x");
  };

  const handleElementSelection = (event) => {
    setSelectedElement(event.target.value);
    setDiagramElement(event.target.value);
    setElementInput({
      type: "",
      distance1: "",
      distance2: "",
      magnitude: "",
      magnitude2: "",
      intensity: "",
    });
    setIntensityFunc(" f(x) = A x");
  };

  // const handleDistanceInput = (event) => {
  //   let currentLocation = event.target.value;
  //   setLocation(currentLocation);
  // };

  // const handleDistanceInput2 = (event) => {
  //   let currentLocation = event.target.value;
  //   setLocation2(currentLocation);
  // };

  // const handleMagnitudeInput = (event) => {
  //   setValue(event.target.value);
  // };

  const handleElementInput = (event) => {
    let { name, value } = event.target;
    setElementInput({ ...elementInput, [name]: value });
    if (name == "magnitude") {
      let newFunc = " f(x) = " + value + " x";
      setIntensityFunc(newFunc);
    }
  };

  return (
    <div className={modalStyles.blurBackground}>
      <div className={modalStyles.modalBox}>
        <div className={modalStyles.modalBanner}>
          <FontAwesomeIcon
            onClick={() => {
              setIsActive(false);
              setElementInput({
                distance1: "",
                distance2: "",
                magnitude: "",
                magnitude2: "",
                intensity: "",
              });
            }}
            className={modalStyles.icon}
            icon="times"
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className={modalStyles.modalBody}>
            <div className={modalStyles.formTitle}>{t("modalHeader")["1"]}</div>
            <div className={modalStyles.inputFormDropdownGrid}>
              <div className={modalStyles.errorSpacer}></div>
              <div className={modalStyles.errorSpacer}></div>
              <label className={modalStyles.formInputLabel} htmlFor="elements">
                {inputType["1"]}:
              </label>
              <select
                className={modalStyles.dropdown}
                name="elements"
                id="elements"
                value={selectedElement}
                onChange={handleElementSelection}
              >
                {/* <option value="roller_support">{elementType["1"]}</option> */}
                <option value="pined_support">{elementType["2"]}</option>
                <option value="fixed_support">{elementType["3"]}</option>
                <option value="point_load">{elementType["4"]}</option>
                <option value="uniform_distributed_load">
                  {elementType["5"]}
                </option>
                <option value="variable_distributed_load">
                  {elementType["6"]}
                </option>
                <option value="moment">{elementType["7"]}</option>
              </select>
              <div className={modalStyles.errorSpacer}></div>
              <div className={modalStyles.errorSpacer}></div>
              {/* <InputForm type={selectedElement} elementLocation={elementLocation} handleDistanceInput={handleDistanceInput} elementInput={elementInput} handleElementInput={handleElementInput} errorMessage={errorMessage} intensityFunc={intensityFunc}/> */}
              <InputForm
                type={selectedElement}
                elementLocation={elementLocation}
                elementInput={elementInput}
                handleElementInput={handleElementInput}
                errorMessage={errorMessage}
                intensityFunc={intensityFunc}
                unitSystem={unitSystem}
                inputType={inputType}
              />
            </div>
            <button
              className={
                isButtonActive
                  ? modalStyles.formButton
                  : modalStyles.formButtonInactive
              }
            >
              {t("buttonName")["1"]}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;
