import modalStyles from "../CSS_Module/modal.module.css";

import { useTranslation } from "react-i18next";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(faTimes);

const TermsWindow = ({
  isTermsWindowActive,
  setIsTermsWindowActive,
  title,
}) => {
  const { t } = useTranslation();
  const terms = t("terms");
  const termsContent = t("termsContent");

  if (!isTermsWindowActive) return null;

  return (
    <div className={modalStyles.blurBackground}>
      <div className={modalStyles.termsModalBox}>
        <div className={modalStyles.modalBanner}>
          <FontAwesomeIcon
            onClick={() => {
              setIsTermsWindowActive(false);
            }}
            className={modalStyles.icon}
            icon="times"
          />
        </div>
        <form>
          <div className={modalStyles.termsModalBody}>
            <div className={modalStyles.formTitle}>{title["4"]}</div>
            <div className={modalStyles.termsWindow}>
              <div>
                <h3>{terms["1"]}</h3>
                <p>{termsContent["1"]}</p>

                <h3>{terms["2"]}</h3>
                <p>{termsContent["2"]}</p>

                <h3>{terms["3"]}</h3>
                <p>{termsContent["3"]}</p>

                <h3>{terms["4"]}</h3>
                <p>{termsContent["4"]}</p>

                <h3>{terms["5"]}</h3>
                <p>{termsContent["5"]}</p>
                <h3>{terms["6"]}</h3>
                <p>{termsContent["6"]}</p>

                <h3>{terms["7"]}</h3>
                <p>{termsContent["7"]}</p>

                <h3>{terms["8"]}</h3>
                <p>{termsContent["8"]}</p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TermsWindow;
