import React from "react";
import styles from "../style.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Terms = () => {
  const { t } = useTranslation();
  const title = t("title");
  const link = t("link");
  const terms = t("terms");
  const termsContent = t("termsContent");

  return (
    <div className={styles.main}>
      <div className={styles.sectionTitleBox}>
        <div className={styles.titleBoxRow}>
          <h1 className={styles.title}>{title["4"]}</h1>
        </div>
      </div>
      <div className={styles.section}>
        <div>
          <h3>{terms["1"]}</h3>
          <p>{termsContent["1"]}</p>

          <h3>{terms["2"]}</h3>
          <p>{termsContent["2"]}</p>

          <h3>{terms["3"]}</h3>
          <p>{termsContent["3"]}</p>

          <h3>{terms["4"]}</h3>
          <p>{termsContent["4"]}</p>

          <h3>{terms["5"]}</h3>
          <p>{termsContent["5"]}</p>
          <h3>{terms["6"]}</h3>
          <p>{termsContent["6"]}</p>

          <h3>{terms["7"]}</h3>
          <p>{termsContent["7"]}</p>

          <h3>{terms["8"]}</h3>
          <p>{termsContent["8"]}</p>
        </div>
      </div>
      <div>
        <div className={styles.link}>
          <Link style={{ textDecoration: "none" }} to="../">
            {link["1"]}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Terms;
