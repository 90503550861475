import reportStyles from "../CSS_Module/Beam_Calculator/report.module.css";

export default function DataPoint({ cx, cy, location, value }) {
  var left = 0;
  var top = 0;

  if (window.innerWidth <= 500) {
    var left = cx + 0 - 4;
    var top = cy + 40 - 4; // 6 is the radius of the dot; 40 is the height of banner + title box
  } else {
    var left = cx + 0 - 4;
    var top = cy + 70 - 4; // 6 is the radius of the dot; 70 is the height of the title box
  }

  return (
    <div
      className={reportStyles.dataPoint}
      style={{ left: `${left}px`, top: `${top}px` }}
    >
      <div className={reportStyles.tooltiptext}>
        [ {location} , {value} ]
      </div>
    </div>
  );
}
