function printMatrix (matrix) {
    const numRows = matrix.length;
    const numCols = matrix[0].length;

    for (let i = 0; i < numRows; i++) {
        console.log(matrix[i])
    }

    console.log("\n")
}

// When using this function, the final matrix should have same number of rows and columns. The rows should be ordered in 
// a way such that diagonal must be nonzero
function EquationSolver(coeffients, constants) {
    const numRows = coeffients.length;
    const numCols = coeffients[0].length;

    const matrix = [];
    for (let i = 0; i < numRows; i++) {
        matrix.push([...coeffients[i], constants[i]]);
    }

    // Perform Gauss-Jordan elimination
    for (let i = 0; i < numRows; i++) {

        let currentPivot = matrix[i][i];

        for (let j = 0; j <= numCols; j++) {
            matrix[i][j] /= currentPivot;
        }


        // Eliminate the coefficent of the current variable in other rows by making them zero
        for (let k = 0; k < numRows; k++) {
            if (k != i) {
                // the coeffient of the pivot variable in the current row
                let factor = matrix[k][i]
                for (let t = 0; t <= numCols; t++) {
                    matrix[k][t] -= factor * matrix[i][t];                     
                }
            }
        }

        // printMatrix (matrix)
    }

    // the the solution from the last column
    const solution = matrix.map(row => row[numCols])

    return solution;

}


export { EquationSolver, printMatrix };

// const coefficients = [
//     [1, -2, 3],
//     [2, 1, 1],
//     [-3, 2, -2]
// ];

// const constants = [7, 4, -10];

// const solution = EquationSolver(coefficients, constants);
// console.log("solution:" + solution );

