import styles from "../style.module.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import React from "react";

library.add(faTimes);

const DisplayCheck = ({}) => {
  return (
    <div className={styles.sizeCheckblurBackground}>
      <div style={{ fontSize: "50px" }}>｡&#x3E;_&#x3C;｡ </div>
      <div style={{ fontSize: "30px", margin: "20px" }}>Oops!</div>
      <div>Expand browser window or use a larger screen</div>
    </div>
  );
};

export default DisplayCheck;
