function drawArrow(ctx, x, y, angle, arrowLength, arrowWeight, color) {
  ctx.save();
  var headlen = 5;
  var angle = angle * (Math.PI / 180);
  ctx.strokeStyle = color;
  ctx.beginPath();
  ctx.moveTo(
    x - arrowLength * Math.cos(angle),
    y - arrowLength * Math.sin(angle)
  );
  ctx.lineTo(x, y);
  ctx.lineWidth = arrowWeight;
  ctx.stroke();
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(
    x - headlen * Math.cos(angle - Math.PI / 7),
    y - headlen * Math.sin(angle - Math.PI / 7)
  );

  ctx.lineTo(
    x - headlen * Math.cos(angle + Math.PI / 7),
    y - headlen * Math.sin(angle + Math.PI / 7)
  );

  ctx.lineTo(x, y);
  ctx.lineTo(
    x - headlen * Math.cos(angle - Math.PI / 7),
    y - headlen * Math.sin(angle - Math.PI / 7)
  );
  ctx.stroke();
  ctx.restore();
}

function drawPointLoad(ctx, x, y, value, unitSystem, plotWidth, beamWidth) {
  if (value == "" || Number(value) == 0) {
    return;
  }
  let height = 0;
  let fontSize = 0;
  if (window.innerWidth <= 500) {
    height = 30;
    fontSize = 12;
  } else {
    height = 80;
    fontSize = 18;
  }
  if (Number(value) > 0) {
    drawArrow(ctx, x, y + beamWidth, 270, height, 3, "black");
    addText(
      ctx,
      x,
      y + height + beamWidth + 20,
      value + " " + unitSystem["0"],
      "horizontal",
      fontSize,
      "black"
    );
  } else {
    drawArrow(ctx, x, y, 90, height, 3, "black");
    addText(
      ctx,
      x,
      y - height - 20,
      value + " " + unitSystem["0"],
      "horizontal",
      fontSize,
      "black"
    );
  }
}

function drawRollerSupport(ctx, x, y, plotWidth) {
  ctx.save();
  ctx.lineWidth = 2;
  ctx.strokeStyle = "black";
  let radius = (25 * 1.732) / 4;
  let groundLineLength = 80;
  ctx.beginPath();
  ctx.arc(x, y + radius, radius, 0, 2 * Math.PI);
  ctx.fillStyle = "black";
  ctx.fill();
  ctx.stroke();
  const grd = ctx.createLinearGradient(
    0,
    y + radius * 2,
    0,
    y + radius * 2 + 20
  );
  grd.addColorStop(0, "black");
  grd.addColorStop(1, "rgba(255, 255, 255, 0)");
  ctx.fillStyle = grd;
  ctx.fillRect(x - groundLineLength / 2, y + radius * 2, groundLineLength, 20);
  ctx.restore();
}

function drawPinedSupport(ctx, x, y, plotWidth) {
  ctx.save();
  ctx.lineWidth = 2;
  ctx.strokeStyle = "black";
  let angle = 60 * (Math.PI / 180);
  let sideLength = 0;
  let groundLineLength = 0;
  let gradientHeight = 0;
  if (window.innerWidth <= 500) {
    sideLength = 10;
    groundLineLength = 40;
    gradientHeight = 10;
  } else {
    sideLength = 25;
    groundLineLength = 80;
    gradientHeight = 20;
  }
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(
    x - sideLength * Math.cos(angle),
    y + sideLength * Math.sin(angle)
  );
  ctx.lineTo(
    x + sideLength * Math.cos(angle),
    y + sideLength * Math.sin(angle)
  );
  ctx.lineTo(x, y);
  ctx.lineTo(
    x - sideLength * Math.cos(angle),
    y + sideLength * Math.sin(angle)
  );
  ctx.fillStyle = "black";
  ctx.fill();
  ctx.stroke();
  const grd = ctx.createLinearGradient(
    0,
    y + sideLength * Math.sin(angle),
    0,
    y + sideLength * Math.sin(angle) + gradientHeight
  );
  grd.addColorStop(0, "black");
  grd.addColorStop(1, "rgba(255, 255, 255, 0)");
  ctx.fillStyle = grd;
  ctx.fillRect(
    x - groundLineLength / 2,
    y + sideLength * Math.sin(angle),
    groundLineLength,
    20
  );
  ctx.restore();
}

function drawFixedSupport(ctx, x, y, beamLeftEnd, beamRightEnd, plotWidth) {
  if (!isNaN(x) && !isNaN(y)) {
    let middlePoint = (beamRightEnd + beamLeftEnd) / 2;
    ctx.save();
    ctx.lineWidth = 2;
    let groundLineLength = 0;
    let gradientWidth = 0;
    if (window.innerWidth <= 500) {
      groundLineLength = 50;
      gradientWidth = 10;
    } else {
      groundLineLength = 95;
      gradientWidth = 30;
    }
    ctx.strokeStyle = "black";
    if (x < middlePoint) {
      const grd = ctx.createLinearGradient(x - gradientWidth, 0, x, 0);
      grd.addColorStop(1, "black");
      grd.addColorStop(0, "rgba(255, 255, 255, 0)");
      ctx.fillStyle = grd;
      ctx.fillRect(x - 30, y - groundLineLength / 2, 30, groundLineLength);
    } else {
      const grd = ctx.createLinearGradient(x, 0, x + gradientWidth, 0);
      grd.addColorStop(1, "rgba(255, 255, 255, 0)");
      grd.addColorStop(0, "black");
      ctx.fillStyle = grd;
      ctx.fillRect(x, y - groundLineLength / 2, 30, groundLineLength);
    }
    ctx.restore();
  }
}

function drawMoment(ctx, x, y, value, unitSystem, plotWidth) {
  if (value == "" || Number(value) == 0) {
    return;
  }
  ctx.save();
  ctx.strokeStyle = "black";
  let radius = 0;
  let fontSize = 0;
  let dotSize = 0;
  if (window.innerWidth <= 500) {
    radius = 15;
    fontSize = 12;
    dotSize = 1;
  } else {
    radius = 30;
    fontSize = 18;
    dotSize = 2;
  }
  let headlen = 5;
  if (Number(value) < 0) {
    let angle = 0.25 * Math.PI;
    ctx.beginPath();
    ctx.lineWidth = 3;
    ctx.arc(x, y, radius, 0.1 * 2 * Math.PI, 0.9 * 2 * Math.PI);
    ctx.stroke();
    let arrowX = x + radius * Math.cos(0.1 * 2 * Math.PI);
    let arrowY = y - radius * Math.sin(0.1 * 2 * Math.PI);

    ctx.beginPath();
    ctx.moveTo(arrowX, arrowY);
    ctx.lineTo(
      arrowX - headlen * Math.cos(angle - Math.PI / 7),
      arrowY - headlen * Math.sin(angle - Math.PI / 7)
    );

    ctx.lineTo(
      arrowX - headlen * Math.cos(angle + Math.PI / 7),
      arrowY - headlen * Math.sin(angle + Math.PI / 7)
    );

    ctx.lineTo(arrowX, arrowY);
    ctx.lineTo(
      arrowX - headlen * Math.cos(angle - Math.PI / 7),
      arrowY - headlen * Math.sin(angle - Math.PI / 7)
    );
    ctx.stroke();
  } else {
    let angle = 0.75 * Math.PI;
    ctx.beginPath();
    ctx.lineWidth = 3;
    ctx.arc(x, y, radius, 0.6 * 2 * Math.PI, 0.4 * 2 * Math.PI);
    ctx.stroke();
    let arrowX = x - radius * Math.cos(0.1 * 2 * Math.PI);
    let arrowY = y - radius * Math.sin(0.1 * 2 * Math.PI);

    ctx.beginPath();
    ctx.moveTo(arrowX, arrowY);
    ctx.lineTo(
      arrowX - headlen * Math.cos(angle - Math.PI / 7),
      arrowY - headlen * Math.sin(angle - Math.PI / 7)
    );

    ctx.lineTo(
      arrowX - headlen * Math.cos(angle + Math.PI / 7),
      arrowY - headlen * Math.sin(angle + Math.PI / 7)
    );

    ctx.lineTo(arrowX, arrowY);
    ctx.lineTo(
      arrowX - headlen * Math.cos(angle - Math.PI / 7),
      arrowY - headlen * Math.sin(angle - Math.PI / 7)
    );
    ctx.stroke();
  }

  //center dot
  ctx.beginPath();
  ctx.arc(x, y, dotSize, 0, 2 * Math.PI);
  ctx.fillStyle = "black";
  ctx.fill();
  ctx.stroke();

  addText(
    ctx,
    x,
    y - radius - 20,
    value + " " + unitSystem["0"] + "⋅" + unitSystem["2"],
    "horizontal",
    fontSize,
    "black"
  );

  ctx.restore();
}

function drawUniformDistributedLoad(
  ctx,
  xFrom,
  xTo,
  y,
  value,
  unitSystem,
  plotWidth,
  beamWidth
) {
  if (value == "" || Number(value) == 0) {
    return;
  }

  if (xFrom < xTo) {
    ctx.save();
    ctx.lineWidth = 3;
    let arrowCount = Math.round((xTo - xFrom) / 40);
    let gap = (xTo - xFrom) / (arrowCount - 1);
    ctx.strokeStyle = "rgb(130,200,54)";
    let height = 0;
    let fontSize = 0;
    if (window.innerWidth <= 500) {
      height = 60;
      fontSize = 12;
    } else {
      height = 150;
      fontSize = 18;
    }
    let xCenter = (xFrom + xTo) / 2;

    if (Number(value) < 0) {
      drawRecWithFill(
        ctx,
        xFrom,
        y,
        xTo - xFrom,
        height,
        "rgba(146,208,80,0.3)",
        0
      );
      drawArrow(ctx, xFrom, y, 90, height, 3, "rgb(130,200,54)");
      drawArrow(ctx, xTo, y, 90, height, 3, "rgb(130,200,54)");
      let x = xFrom;
      for (let i = 0; i < arrowCount - 2; i++) {
        x += gap;
        drawArrow(ctx, x, y, 90, 0, 3, "rgb(130,200,54)");
      }
      ctx.beginPath();
      ctx.moveTo(xFrom - 2, y - height);
      ctx.lineTo(xTo + 2, y - height);
      ctx.stroke();
      addText(
        ctx,
        xCenter,
        y - height - 20,
        value + " " + unitSystem["0"] + "/" + unitSystem["2"],
        "horizontal",
        fontSize,
        "black"
      );
    } else {
      drawRecWithFill(
        ctx,
        xFrom,
        y + height + beamWidth,
        xTo - xFrom,
        height,
        "rgba(146,208,80,0.3)",
        0
      );
      drawArrow(ctx, xFrom, y + beamWidth, 270, height, 3, "rgb(130,200,54)");
      drawArrow(ctx, xTo, y + beamWidth, 270, height, 3, "rgb(130,200,54)");
      let x = xFrom;
      for (let i = 0; i < arrowCount - 2; i++) {
        x += gap;
        drawArrow(ctx, x, y + beamWidth, 270, 0, 3, "rgb(130,200,54)");
      }
      ctx.beginPath();
      ctx.moveTo(xFrom, y + height + beamWidth);
      ctx.lineTo(xTo, y + height + beamWidth);
      ctx.stroke();
      addText(
        ctx,
        xCenter,
        y + height + beamWidth + 20,
        value + " " + unitSystem["0"] + "/" + unitSystem["2"],
        "horizontal",
        fontSize,
        "black"
      );
    }
    ctx.restore();
  }
}

function drawVariableDistributedLoad(
  ctx,
  xFrom,
  xTo,
  y,
  value,
  value2,
  unitSystem,
  plotWidth,
  beamWidth
) {
  if (value == "" || value2 == "" || Number(value * value2) < 0) {
    return;
  }

  if (Number(value) == 0 && Number(value2) == 0) {
    return;
  }

  if (xFrom < xTo) {
    ctx.save();
    ctx.lineWidth = 3;
    ctx.strokeStyle = "rgb(187, 187, 187)";
    let height = 0;
    let fontSize = 0;
    if (window.innerWidth <= 500) {
      height = 100;
      fontSize = 12;
    } else {
      height = 250;
      fontSize = 18;
    }
    let maxValue = Math.max(Math.abs(value), Math.abs(value2));
    let arrowCount = Math.round((xTo - xFrom) / 40);
    let gap = (xTo - xFrom) / (arrowCount - 1);

    if (Number(value) < 0 || Number(value2) < 0) {
      drawArrow(
        ctx,
        xFrom,
        y,
        90,
        (-value / maxValue) * height,
        3,
        "rgb(187, 187, 187)"
      );
      drawArrow(
        ctx,
        xTo,
        y,
        90,
        (-value2 / maxValue) * height,
        3,
        "rgb(187, 187, 187)"
      );
      let x = xTo;

      for (let i = 0; i < arrowCount - 2; i++) {
        x -= gap;
        drawArrow(ctx, x, y, 90, 0, 3, "rgb(187, 187, 187)");
      }
      ctx.beginPath();
      ctx.fillStyle = "rgba(91, 155, 213, 0.3)";
      ctx.moveTo(xFrom, y + (value / maxValue) * height);
      ctx.lineTo(xTo, y + (value2 / maxValue) * height);
      ctx.stroke();
      ctx.lineTo(xTo, y);
      ctx.lineTo(xFrom, y);
      ctx.lineTo(xFrom, y - (-value / maxValue) * height);
      ctx.fill();

      addText(
        ctx,
        xFrom - 10,
        y - (-value / maxValue) * height - 20,
        value + " " + unitSystem["0"] + "/" + unitSystem["2"],
        "horizontal",
        fontSize,
        "black"
      );
      addText(
        ctx,
        xTo + 10,
        y - (-value2 / maxValue) * height - 20,
        value2 + " " + unitSystem["0"] + "/" + unitSystem["2"],
        "horizontal",
        fontSize,
        "black"
      );
    } else {
      //When mag > 0, the load should be under the beam
      drawArrow(
        ctx,
        xFrom,
        y + beamWidth,
        270,
        (value / maxValue) * height,
        3,
        "rgb(187, 187, 187)"
      );
      drawArrow(
        ctx,
        xTo,
        y + beamWidth,
        270,
        (value2 / maxValue) * height,
        3,
        "rgb(187, 187, 187)"
      );
      let x = xFrom;

      for (let i = 0; i < arrowCount - 2; i++) {
        x += gap;
        drawArrow(ctx, x, y + beamWidth, 270, 0, 3, "rgb(187, 187, 187)");
      }
      ctx.beginPath();
      ctx.fillStyle = "rgba(91, 155, 213, 0.3)";
      ctx.moveTo(xFrom, y + (value / maxValue) * height + beamWidth);
      ctx.lineTo(xTo, y + (value2 / maxValue) * height + beamWidth);
      ctx.stroke();
      ctx.lineTo(xTo, y + beamWidth);
      ctx.lineTo(xFrom, y + beamWidth);
      ctx.lineTo(xFrom, y + (value / maxValue) * height);
      ctx.fill();

      addText(
        ctx,
        xFrom - 10,
        y + (value / maxValue) * height + 20 + beamWidth,
        value + " " + unitSystem["0"] + "/" + unitSystem["2"],
        "horizontal",
        fontSize,
        "black"
      );
      addText(
        ctx,
        xTo + 10,
        y + (value2 / maxValue) * height + 20 + beamWidth,
        value2 + " " + unitSystem["0"] + "/" + unitSystem["2"],
        "horizontal",
        fontSize,
        "black"
      );
    }

    ctx.restore();
  }
}

function drawDashedLine(ctx, fromx, fromy, tox, toy, pattern) {
  ctx.save();
  ctx.strokeStyle = "grey";
  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.setLineDash(pattern);
  ctx.moveTo(fromx, fromy);
  ctx.lineTo(tox, toy);
  ctx.stroke();
  ctx.restore();
}

function drawSolidLine(ctx, fromx, fromy, tox, toy, color, lineWidth) {
  ctx.beginPath();
  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;
  ctx.moveTo(fromx, fromy);
  ctx.lineTo(tox, toy);
  ctx.stroke();
}

function drawRec(ctx, x, y, width, height) {
  ctx.beginPath();
  ctx.strokeStyle = "grey";
  ctx.rect(x, y, width, height);
  ctx.stroke();
}

function drawBeam(ctx, x, length, y, plotWidth) {
  let beamWidth = 0;

  if (window.innerWidth <= 500) {
    beamWidth = 20;
  } else {
    beamWidth = 40;
  }

  ctx.save();
  ctx.fillStyle = "rgb(166, 166, 166)";
  ctx.fillRect(x, y - beamWidth / 2, length, beamWidth);
  ctx.restore();
}

// function drawPlusSign(ctx, x, y) {
//     ctx.beginPath();
//     const size=20;
//     ctx.moveTo(x-size/2, y);
//     ctx.lineTo(x+size/2, y);
//     ctx.stroke();
//     ctx.beginPath();
//     ctx.moveTo(x, y-size/2);
//     ctx.lineTo(x, y+size/2);
//     ctx.stroke();
// }

function addText(ctx, x, y, content, direction, size, color) {
  ctx.save();
  ctx.fillStyle = color;
  ctx.font = size + "px Arial";
  ctx.textBaseline = "middle";
  ctx.textAlign = "center";
  if (direction == "vertical") {
    ctx.translate(x, y);
    var angle = 270 * (Math.PI / 180);
    ctx.rotate(angle);
    ctx.fillText(content, 0, 0);
  } else {
    ctx.fillText(content, x, y);
  }
  ctx.restore();
}

function drawGrid(ctx, x, y, plotWidth, plotHeight, axisY) {
  let step = 0;

  if (window.innerWidth <= 500) {
    step = 200;
  } else {
    step = 260;
  }

  let fineStep = step / 5;
  let currentX = x + step;
  let currentFineLineX = x + fineStep;
  let currentY = axisY;

  ctx.save();

  while (currentFineLineX < x + plotWidth) {
    drawSolidLine(
      ctx,
      currentFineLineX,
      y,
      currentFineLineX,
      y + plotHeight,
      "rgb(187, 187, 187)",
      1
    );
    currentFineLineX += fineStep;
  }

  while (currentY < y + plotHeight) {
    drawSolidLine(
      ctx,
      x,
      currentY,
      x + plotWidth,
      currentY,
      "rgb(187, 187, 187)",
      1
    );
    currentY += fineStep;
  }

  currentY = axisY - fineStep;
  while (currentY > y) {
    drawSolidLine(
      ctx,
      x,
      currentY,
      x + plotWidth,
      currentY,
      "rgb(187, 187, 187)",
      1
    );
    currentY -= fineStep;
  }

  ctx.restore();
}

function drawRecWithFill(ctx, x, y, width, height, color) {
  ctx.save();
  ctx.beginPath();
  ctx.fillStyle = color;
  ctx.fillRect(x, y - height, width, height);
  ctx.stroke();
  ctx.restore();
}

export {
  drawPointLoad,
  drawRollerSupport,
  drawPinedSupport,
  drawFixedSupport,
  drawUniformDistributedLoad,
  drawVariableDistributedLoad,
  drawMoment,
  drawDashedLine,
  drawSolidLine,
  drawRec,
  drawBeam,
  addText,
  drawGrid,
};
