import BeamElement from "./element.js";

export default class DistributedLoad extends BeamElement{
    constructor(type, location1, location2, forceDistribution, forceDistribution2) {
        super(type);
        if ( type =='distributed_load'){
            this.location = (location1+location2)/2;
            this.magnitude = forceDistribution*Math.abs(location2-location1);
            this.from = location1;
            this.to = location2;
            this.forceDistribution = forceDistribution; 
        } else if ( type =='variable_distributed_load') {
            // this.location = (2/3)*Math.abs(location1+location2);
            // this.magnitude =(forceDistribution*Math.pow(Math.abs(location2-location1),2))/2;
            // this.from = location1;
            // this.to = location2;
            // this.forceDistribution = forceDistribution; 

            let magDiff = Math.abs(forceDistribution2-forceDistribution);
            let locationDiff = Math.abs(location2-location1);
            let minMag = (Math.min(forceDistribution,forceDistribution2));
            let triCentroidMultiplier = (minMag==forceDistribution ? 2/3 : 1/3);            
            let mag = (forceDistribution+forceDistribution2)*locationDiff/2;

            this.magnitude = (forceDistribution+forceDistribution2)*locationDiff/2;
            this.location = ((((magDiff*locationDiff*0.5)*(locationDiff*triCentroidMultiplier))+((minMag*locationDiff)*(locationDiff*0.5)))/mag)+location1;            
            this.from = location1;
            this.to = location2;
            this.forceDistribution = forceDistribution; 
            this.forceDistribution2 = forceDistribution2; 
            this.intercept = forceDistribution;
            this.slope = (forceDistribution2-forceDistribution)/locationDiff;
            // this.centroidRatio = (this.location-location1)/locationDiff; //centroid location is not the same across x

        }
    }
} 