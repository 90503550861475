
export default class BeamElement{
    constructor(type, location, magnitude) {
   
        this.type = type;
        this.location = location;
        this.magnitude = magnitude; 

    }

    getType() {
        return this.type;
    }

    getLocation() {
        return this.location;
    }

    getMagnitude() {
        return this.magnitude;
    }

} 



