import { useState } from "react";
import ElementListPanel from "./Component/element_list";
import Header from "./Component/header";
import Toolbar from "./Component/toolbar";
import styles from "./style.module.css";
import Modal from "./Component/new_element_input";
import Diagram from "./Component/beam_diagram";
import Result from "./Component/result";
import Footer from "./Component/footer";
import BeamSelectionModal from "./Component/beam_selection_modal";
import PreCalculationErrorModal from "./Component/pre-calculation_error";
import Settings from "./Component/settings";
import i18n from "./i18nextService";
import { useTransition, useTranslation } from "react-i18next";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import About from "./Pages/about";
import BeamCalculator from "./beam_solver";
import Terms from "./Pages/terms";
import Contact from "./Pages/contact";
import ThankYou from "./Pages/thankyou";

function App() {
  const [element, setElement] = useState("");
  // const [elementList, setElementList] = useState([]);
  const [diagramElement, setDiagramElement] = useState("");
  const [diagramElementList, setDiagramElementList] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isBeamSelectionActive, setIsBeamSelectionActive] = useState(false);
  const [isSettingActive, setIsSettingActive] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedElement, setSelectedElement] = useState("roller_support");
  const [elementLocation, setLocation] = useState("");
  const [elementLocation2, setLocation2] = useState("");
  const [elementValue, setValue] = useState("");
  const [beamLengthValue, setBeamLengthValue] = useState("100");
  const initialValues = {
    distance1: "",
    distance2: "",
    magnitude: "",
    intensity: "",
  };
  const [elementInput, setElementInput] = useState(initialValues);
  const [errorMessage, setErrorMessage] = useState(initialValues);
  const [isPreCalculationError, setIsPreCalculationError] = useState(false);
  const [preCalculationError, setPreCalculationError] = useState("");
  const [unitSystem, setUnitSystem] = useState(["N", "kg", "m"]);
  const [currentUnit, setCurrentUnit] = useState("metric");
  const [isLanguageMenuActive, setIsLanguageMenuActive] = useState(false);
  const [elementList, setElementList] = useState([
    {
      content: "beam",
      location: "",
      location2: "",
      value: "",
      value2: "",
      error: "",
      id: "beamBody",
      length: beamLengthValue,
    },
  ]);
  const [canvasSize, setCanvasSize] = useState({
    width: window.innerWidth * 0.8 - 320,
    height: 704,
  });

  const { t } = useTranslation();
  const elementTypeTranslation = t("elementType");
  const inputType = t("inputType");
  const beamType = t("beamType");
  const buttonName = t("buttonName");
  const header = t("header");
  const modalHeader = t("modalHeader");
  const diagramType = t("diagramType");
  const dataCheckErrorMsg = t("dataCheckErrorMsg");

  return (
    <BrowserRouter>
      <Header
        header={header}
        i18n={i18n}
        isLanguageMenuActive={isLanguageMenuActive}
        setIsLanguageMenuActive={setIsLanguageMenuActive}
      />
      <Routes>
        <Route path="/">
          <Route index element={<BeamCalculator />} />
          <Route path="about" element={<About />} />
          <Route path="terms" element={<Terms />} />
          <Route path="contact" element={<Contact />} />
          {/* <Route path="thankyou" element={<ThankYou />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
